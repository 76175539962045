import * as Themes from '../themes';
import merge from 'lodash/merge';
import { Themes as ThemeTypes } from '../constants/shared';
import { lens } from '@dhmk/zustand-lens';
import { Theme } from '../types/shared';
export interface ThemeSlice {
  theme: Theme;
  activeThemeName: ThemeTypes;
  overrides: Partial<Theme>;
  applyCSSOverrides: (cssOverrides: string) => void;
  applyThemeOverrides: (overrides: Partial<Theme>) => void;
  updateTheme: (theme: ThemeTypes) => void;
  cssOverrides: string;
}

export const themeSlice: ThemeSlice = lens((setState, getState) => ({
  theme: Themes.Tea,
  activeThemeName: ThemeTypes.Tea,
  overrides: {},
  cssOverrides: '',
  applyThemeOverrides: (overrides?: Partial<Theme>) => {
    if (!overrides) {
      setState({ theme: { ...Themes[getState().activeThemeName] } });
    }
    setState({ theme: merge({}, getState().theme, overrides) });
  },
  applyCSSOverrides: (cssOverrides: string) => {
    setState({ cssOverrides });
  },
  updateTheme: (theme: ThemeTypes) => {
    setState({
      theme: merge(Themes[theme], getState().overrides),
      activeThemeName: theme,
    });
  },
}));
