import { useStore } from '../../store/store';
import { Notification as MantineNotification } from '@mantine/core';
import { MantineColors, NotificationLevels } from '../../enums';
import { NotificationType } from '../../store/notificationSlice';
import { useEffect } from 'react';

type NotificationProps = {
  notification: NotificationType;
  className?: string;
};

const Notification = ({ notification, className }: NotificationProps) => {
  const { level, id, body } = notification;
  const dismissNotification = useStore((s) => s.notificationSlice.dismiss);
  let color = MantineColors.CYAN;

  switch (level) {
    case NotificationLevels.SUCCESS:
      color = MantineColors.GREEN;
      break;
    case NotificationLevels.WARNING:
      color = MantineColors.YELLOW;
      break;
    case NotificationLevels.ERROR:
      color = MantineColors.RED;
      break;
    case NotificationLevels.INFO:
    default:
  }

  return (
    <MantineNotification
      className={`z-10 ml-8 mb-2 ${className || ''}`}
      onClose={() => {
        dismissNotification(id);
      }}
      color={color}
      disallowClose={notification.isCloseable === false}
    >
      {body}
    </MantineNotification>
  );
};

export default Notification;
