import { css } from '@emotion/react';
import { useStore } from '../../store/store';

type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  /**
   * Label for the Link. Can be a full message if you only want to linkify part of it.
   * See `linkedText` below for more details
   */
  label: string;
  /**
   * Optionally, you can send in a whole message as a label, and specify
   * with this prop if you want to linkify part of that text.
   * For example
   * label: 'Hello World',
   * linkedText: 'Hello',
   * would generate <span><a...>Hello</a...> World</span>
   */
  linkedText: string;
  isPrimary: boolean;
};

const Link = (props: LinkProps) => {
  const { linkedText, label, isPrimary, ...rest } = props;
  const [firstPart, lastPart] = props.label.split(props.linkedText);

  return (
    <span className={`text-xs md:text-base && ${props.className || ''}`}>
      {!!props.linkedText && firstPart}
      <a
        {...rest}
        href={props.href}
        css={css`
          &:hover {
          }

          &:active {
          }

          &:disabled {
            cursor: not-allowed;
          }
        `}
        className={`underline text-xs md:text-base ${props.className || ''}`}
      >
        {props.linkedText || firstPart}
      </a>
      {!!props.linkedText && lastPart}
    </span>
  );
};

Link.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
  isPrimary: true,
  linkedText: undefined,
};

export default Link;
