import {
  DEFAULT_WIDGET_WIDTH,
  FULL_TOKEN_WIDTH,
  TOKEN_PADDING,
  WidgetState,
} from '../constants/shared';
import {
  PlutoSDK,
  PresentationMode,
  UserAgent,
  WIDTH_BREAKPOINTS,
  WIDTH_BREAKPOINT_TYPES,
  WindowDimensionsChangedPayload,
} from '../types/shared';
import { lens } from '@dhmk/zustand-lens';
import { useStore } from './store';
import UAParser from 'ua-parser-js';

export interface UniversalSlice {
  widgetState: WidgetState;
  teamID?: string;
  hostOrigin: string;
  url: string;
  sdk: PlutoSDK;
  windowDimensions: WindowDimensionsChangedPayload;
  setSDK: (sdk: PlutoSDK) => void;
  updateHostOrigin: (hostOrigin: string) => void;
  updateUrl: (url: string) => void;
  updateWindowDimensions: (
    windowDimensions: WindowDimensionsChangedPayload
  ) => void;
  hydrateWidgetDimensions: () => void;
  widthBreakpoint?: WIDTH_BREAKPOINT_TYPES;
  userAgent: UserAgent;
  isMobile: boolean;
  widgetDimensions: { tokenWidth: number; contentWidth: number };
}

export const universalSlice: UniversalSlice = lens((setState) => ({
  hostOrigin: '',
  url: '',
  widgetState: WidgetState.Uninitialized,
  sdk: undefined,
  widgetDimensions: { tokenWidth: 0, contentWidth: DEFAULT_WIDGET_WIDTH },
  windowDimensions: { width: 0, height: 0 },
  userAgent: new UAParser().getResult() as UserAgent,
  isMobile: new UAParser().getResult().device.type === 'mobile',
  setSDK: (sdk: PlutoSDK) => setState({ sdk }),
  updateWidgetState: (widgetState: WidgetState) => setState({ widgetState }),
  updateHostOrigin: (hostOrigin: string) => setState({ hostOrigin }),
  updateUrl: (url: string) => setState({ url }),
  hydrateWidgetDimensions: () =>
    setState({ widgetDimensions: calculateMainAreaWidth() }),
  updateWindowDimensions: (
    windowDimensions: WindowDimensionsChangedPayload
  ) => {
    const { width } = windowDimensions;
    let widthBreakpoint: WIDTH_BREAKPOINT_TYPES | undefined = undefined;
    if (width <= WIDTH_BREAKPOINTS.SM) {
      widthBreakpoint = WIDTH_BREAKPOINT_TYPES.SM;
    } else if (width <= WIDTH_BREAKPOINTS.MD) {
      widthBreakpoint = WIDTH_BREAKPOINT_TYPES.MD;
    } else if (width <= WIDTH_BREAKPOINTS.LG) {
      widthBreakpoint = WIDTH_BREAKPOINT_TYPES.LG;
    } else if (width <= WIDTH_BREAKPOINTS.XL) {
      widthBreakpoint = WIDTH_BREAKPOINT_TYPES.XL;
    } else if (width <= WIDTH_BREAKPOINTS.XXL) {
      widthBreakpoint = WIDTH_BREAKPOINT_TYPES.XXL;
    }

    const newUA = new UAParser().getResult();
    const isMobile = newUA.device.type === 'mobile';
    const widgetDimensions = calculateMainAreaWidth(
      windowDimensions,
      widthBreakpoint,
      isMobile
    );

    setState({
      windowDimensions,
      widthBreakpoint,
      userAgent: newUA,
      isMobile,
      widgetDimensions,
    });
  },
}));

const calculateMainAreaWidth = (
  windowDimensions?: WindowDimensionsChangedPayload,
  widthBreakpoint?: WIDTH_BREAKPOINT_TYPES,
  isMobile?: boolean
) => {
  const state = useStore.getState();
  const isHorizontalMode =
    state.projectSlice.appState.presentationMode ===
      PresentationMode.HORIZONTAL_IMAGE && state.projectSlice.imageUrl;

  const dimensions = { tokenWidth: 0, contentWidth: DEFAULT_WIDGET_WIDTH };
  const winDimensions =
    windowDimensions || state.universalSlice.windowDimensions;
  const widthBP = widthBreakpoint || state.universalSlice.widthBreakpoint;
  const mobile =
    typeof isMobile === 'undefined' ? state.universalSlice.isMobile : isMobile;

  if (mobile || applyMediaWidth(WIDTH_BREAKPOINT_TYPES.SM, widthBP)) {
    dimensions.contentWidth = winDimensions.width;
  } else if (applyMediaWidth(WIDTH_BREAKPOINT_TYPES.MD, widthBP)) {
    dimensions.contentWidth = Math.floor(
      Math.min(dimensions.contentWidth, winDimensions.width * 0.9)
    );
  } else if (applyMediaWidth(WIDTH_BREAKPOINT_TYPES.LG, widthBP)) {
    dimensions.contentWidth = Math.floor(
      Math.min(dimensions.contentWidth, winDimensions.width * 0.8)
    );
  } else if (isHorizontalMode) {
    dimensions.tokenWidth = 0;
  }

  return dimensions;
};

export const applyMediaWidth = (
  breakpoint?: WIDTH_BREAKPOINT_TYPES,
  widthBreakpoint?: WIDTH_BREAKPOINT_TYPES
) => {
  const currentBreakpoint =
    widthBreakpoint || useStore.getState().universalSlice.widthBreakpoint;

  switch (breakpoint) {
    case WIDTH_BREAKPOINT_TYPES.XXL: {
      return !!currentBreakpoint;
    }
    case WIDTH_BREAKPOINT_TYPES.XL: {
      return (
        !!currentBreakpoint && currentBreakpoint !== WIDTH_BREAKPOINT_TYPES.XXL
      );
    }
    case WIDTH_BREAKPOINT_TYPES.LG: {
      return (
        !!currentBreakpoint &&
        ![WIDTH_BREAKPOINT_TYPES.XXL, WIDTH_BREAKPOINT_TYPES.XL].includes(
          currentBreakpoint
        )
      );
    }
    case WIDTH_BREAKPOINT_TYPES.MD: {
      return [WIDTH_BREAKPOINT_TYPES.SM, WIDTH_BREAKPOINT_TYPES.MD].includes(
        currentBreakpoint
      );
    }
    case WIDTH_BREAKPOINT_TYPES.SM: {
      return currentBreakpoint === WIDTH_BREAKPOINT_TYPES.SM;
    }
    default:
      return false;
  }
};

export const applyPhoneStyling = (widthBreakpoint?: WIDTH_BREAKPOINT_TYPES) =>
  [WIDTH_BREAKPOINT_TYPES.SM].includes(
    useStore.getState().universalSlice.widthBreakpoint
  ) || useStore.getState().universalSlice.isMobile;
