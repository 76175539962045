import { css } from '@emotion/react';
import { PhaseKey } from '../../constants/shared';
import { useStore } from '../../store/store';
import CountdownTimer from '../mintkitAtoms/CountdownTimer';

const ONE_DAY = 1000 * 60 * 60 * 24;
const padTime = (time: number) => (time < 10 ? `0${time}` : time);

type GeneralComingSoonProps = {
  inactive?: boolean;
};

const GeneralComingSoon = (props: GeneralComingSoonProps) => {
  const phase = useStore((s) => s.projectSlice.appState.phase);
  const updateProjectPhaseKey = useStore(
    (s) => s.projectSlice.updateProjectPhaseKey
  );
  const startsAt = phase?.startsAt ? new Date(phase.startsAt) : null;
  const now = Date.now();
  const theme = useStore((s) => s.themeSlice.theme);

  if (props.inactive) {
    console.warn(
      'Warning: Project not configured, visit our guide for more information. https://docs.pluto.xyz'
    );
  }

  return (
    <div
      css={css`
        color: white; important!
      `}
      className="flex flex-col p-8 items-center justify-center h-full relative"
    >
      <h2
        className="text-3xl mb-8 text-center"
        css={css`
          color: ${theme.canvas.text};
        `}
      >
        Check back soon for updates!
      </h2>
      {startsAt?.getTime() > now && (
        <CountdownTimer
          targetTime={startsAt}
          onComplete={() => {
            updateProjectPhaseKey(PhaseKey.active);
          }}
        />
      )}
    </div>
  );
};

export default GeneralComingSoon;
