import { useStore } from '../store/store';
import parseUrl from 'parse-url';
import { useEffect } from 'react';
import { notifyError } from '../store/notificationSlice';
import formatMessage from '../messages';
import { ProjectPhase } from '../constants/shared';

const LoadErrorHandler = () => {
  const url = useStore((s) => s.universalSlice.url);
  const isProjectLoaded = useStore((s) => s.projectSlice.isProjectLoaded);
  const phase = useStore((s) => s.projectSlice.appState.phase?.kind);
  const areMissionsLoaded = useStore((s) => s.missionSlice.areMissionsLoaded);
  const updateLoadError = useStore((s) => s.notificationSlice.updateLoadError);
  useEffect(() => {
    if (
      !url ||
      !isProjectLoaded ||
      (phase === ProjectPhase.EarlyAccess && !areMissionsLoaded)
    )
      return;

    const { query } = parseUrl(url);

    if (query.error) {
      const errorMessage = formatMessage(query.error);
      notifyError(errorMessage, 'loadError');

      if (query.mission_key) {
        updateLoadError({
          message: errorMessage,
          type: query.mission_key,
        });
      }
    }
  }, [url, isProjectLoaded, areMissionsLoaded, phase]);

  return null;
};

export default LoadErrorHandler;
