import { Theme } from '../types/shared';
import { Colors } from './colors';

const Pluto: Theme = {
  primary: {
    background: Colors.PINK,
    text: Colors.DARK_GRAY,
  },
  secondary: {
    background: Colors.LIGHT_PINK,
    text: Colors.DARK_GRAY,
  },
  canvas: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionButton: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionBody: {
    background: Colors.LIGHT_GRAY,
    text: Colors.DARK_GRAY,
  },
};

export default Pluto;
