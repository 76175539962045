import { Theme } from '../types/shared';
import { Colors } from './colors';

const Chrome: Theme = {
  primary: {
    background: Colors.CHROME,
    text: Colors.DARK_GRAY,
  },
  secondary: {
    background: Colors.GRAY,
    text: Colors.DARK_GRAY,
  },
  canvas: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionButton: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionBody: {
    background: Colors.LIGHT_GRAY,
    text: Colors.DARK_GRAY,
  },
};
export default Chrome;
