import { Missions } from '../../../../../store/missionSlice';
import { useStore } from '../../../../../store/store';
import DisclosureBody from './Disclosure/DisclosureBody';
import DisclosureButton from './Disclosure/DisclosureButton';
import DiscordInvitation from './DiscordInvitation';

export type DiscordPublicData =
  | {
      guild_id: string;
      invite_link: string;
      role_id: string;
      guild_name: string;
    }
  | undefined;

const DiscordMission = () => {
  const mission = useStore((s) => s.missionSlice.missions[Missions.discord]);
  const connectDiscord = useStore((s) => s.discordSlice.connectDiscord);
  const publicConfig = mission.publicConfig as DiscordPublicData;
  const loadError = useStore((s) => s.notificationSlice.loadError);
  const errorMessage =
    loadError?.type === Missions.discord ? loadError.message : null;

  return (
    <>
      <DisclosureButton mission={Missions.discord} name={`Join the Discord`} />
      <DisclosureBody
        mission={Missions.discord}
        description="Join the Discord community to complete this step."
        errorMessage={errorMessage}
        actionButton={{
          onClick: connectDiscord,
          label: 'Sign in with Discord',
        }}
      />
    </>
  );
};

export default DiscordMission;
