import { StoreApi } from 'zustand';
import axios from '../../async/axios';
import * as TwitterProto from '../../async/proto/missions/twitter/twitter';
import { lens } from '@dhmk/zustand-lens';
import { Store } from '../store';
export interface TwitterSlice {
  connectTwitter: () => Promise<void>;
}

export const twitterSlice: TwitterSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    connectTwitter: async () => {
      const { missionSlice, universalSlice } = api.getState();

      const missionId = missionSlice.missions.twitter.internalID;
      const finalRedirectUrl = universalSlice.hostOrigin;
      const sdk = universalSlice.sdk;
      const { data } = await axios.post(`/missions/twitter/complete`, {
        missionId,
        finalRedirectUrl,
      } as TwitterProto.CompleteTwitterMissionRequest);
      const { redirectUrl } =
        TwitterProto.CompleteTwitterMissionResponse.fromJson(data);

      if (redirectUrl) {
        sdk.redirect(redirectUrl);
      }
    },
  })
);
