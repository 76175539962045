import { lens } from '@dhmk/zustand-lens';
import { Missions } from './missionSlice';
import produce from 'immer';
import { NotificationLevels } from '../enums';
import { useStore } from './store';

export const DEFAULT_NOTIFICATION_DURATION = 5000;

export type NotificationType = {
  level: NotificationLevels;
  id: string;
  body?: string | React.ReactNode;
  duration?: number;
  isCloseable?: boolean;
};

export type LoadError = {
  type?: string;
  message: string;
};

export interface NotificationSlice {
  notifications: NotificationType[];
  missionNotifications: Record<Missions, NotificationType[]>;
  loadError?: LoadError;
  notify: (notification: NotificationType, missionID?: Missions) => void;
  dismiss: (notificationID: string, missionID?: string) => void;
  updateLoadError?: (error?: LoadError) => void;
}

export const notificationSlice: NotificationSlice = lens(
  (setState, getState) => ({
    notifications: [],
    missionNotifications: {
      [Missions.discord]: [],
      [Missions.TwitterMission]: [],
      [Missions.EthMission]: [],
      [Missions.NFTMission]: [],
      [Missions.WalletMission]: [],
      [Missions.RegistrationMission]: [],
    },
    notify: (notification: NotificationType, missionID?: Missions) => {
      const duration = notification.duration || DEFAULT_NOTIFICATION_DURATION;

      setState(
        produce((state: NotificationSlice) => {
          const objToUpdate = missionID
            ? state.missionNotifications[missionID]
            : state.notifications;

          const idExists = objToUpdate.findIndex(
            (n) => n.id === notification.id
          );
          if (idExists !== -1) {
            objToUpdate[idExists] = { ...notification, duration };
          } else {
            objToUpdate.push({
              ...notification,
              duration,
            });
          }
        })
      );

      setTimeout(() => {
        setState(
          produce((state: NotificationSlice) => {
            if (missionID) {
              state.missionNotifications[missionID] =
                state.missionNotifications[missionID].filter(
                  (n) => n.id !== notification.id
                );
            } else {
              state.notifications = state.notifications.filter(
                (n) => n.id !== notification.id
              );
            }
          })
        );
      }, duration);
    },
    dismiss: (notificationID: string, missionID?: string) => {
      setState(
        produce((state: NotificationSlice) => {
          if (missionID) {
            state.missionNotifications[missionID] = state.missionNotifications[
              missionID
            ].filter(
              (notification: NotificationType) =>
                notification.id !== notificationID
            );
          } else {
            state.notifications = state.notifications.filter(
              (notification: NotificationType) =>
                notification.id !== notificationID
            );
          }
        })
      );
    },
    updateLoadError: (loadError?: LoadError) => setState({ loadError }),
  })
);

const notify = (notification: Partial<NotificationType>) =>
  useStore.getState().notificationSlice.notify({
    level: notification.level,
    id: notification.id,
    body: notification.body,
    duration: DEFAULT_NOTIFICATION_DURATION,
  });

export const notifyError = (body: string, id?: string) => {
  const idToUse = id || `${Math.random()}`;
  notify({ level: NotificationLevels.ERROR, body, id: idToUse });
  return idToUse;
};

export const notifyWarning = (body: string, id?: string) => {
  const idToUse = id || `${Math.random()}`;
  notify({ level: NotificationLevels.WARNING, body, id: idToUse });
  return idToUse;
};

export const notifySuccess = (body: string, id?: string) => {
  const idToUse = id || `${Math.random()}`;
  notify({ level: NotificationLevels.SUCCESS, body, id: idToUse });
  return idToUse;
};

export const notifyInfo = (body: string, id?: string) => {
  const idToUse = id || `${Math.random()}`;
  notify({ level: NotificationLevels.INFO, body, id: idToUse });
  return idToUse;
};
