import { css } from '@emotion/react';
import { IconX } from '@tabler/icons';
import { rgba } from 'emotion-rgba';
import { Colors } from '../../themes/colors';

type CloseButtonProps = {
  size?: number;
  color?: Colors;
  onClick: () => void;
  className?: string;
};

const CloseButton = (props: CloseButtonProps) => {
  return (
    <IconX
      css={css`
        min-width: ${props.size}px;
        min-height: ${props.size}px;
        width: ${props.size}px;
        height: ${props.size}px;
        stroke-width: 2px;

        // White transition looks ew
        ${props.color !== Colors.WHITE &&
        `
          &:hover {
            color: ${rgba(props.color, 0.8)};
          }
          &:active {
            color: ${rgba(props.color, 0.9)};
          }
        `}
      `}
      className={`cursor-pointer transition-color hover:scale-105 active:scale-95 ${
        props.className || ''
      }`}
      onClick={props.onClick}
    />
  );
};

CloseButton.defaultProps = {
  color: Colors.BLACK,
  size: 24,
};

export default CloseButton;
