import './index.css';
import * as ReactDOM from 'react-dom/client';
import App from './App';

const sdkScript = document.createElement('script');
sdkScript.type = 'text/javascript';
sdkScript.src = process.env.SDK_SCRIPT_URL;
sdkScript.onload = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(<App />);
};
document.head.appendChild(sdkScript);

if ('serviceWorker' in navigator && process.env.ENVIRONMENT !== 'DEVELOPMENT') {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}
