export enum Colors {
  WHITE = '#ffffff',
  BLACK = '#000000',
  TWITTER_BLUE = '#1DA1F2',
  YELLOW = '#FFE231',
  SUNSHINE = '#FEF29F',
  LIGHT_GRAY = '#FAFAFA',
  GRAY = '#F5F5F5',
  DARK_GRAY = '#222222',
  DARKER_GRAY = '#111111',
  DISCORD_PURPLE = '#5865F2',
  CHROME = '#DEDEDE',
  PURPLE = '#6B4BC3',
  LAVENDER = '#DCABEB',
  LIGHT_PINK = '#FFF1F2',
  PINK = '#FFB0C1',
  TEA = '#C6D39C',
  FROG_ON_A_LOG = '#BFBB98',
  CREAMY_MOCHA = '#DACCBE',
  RED_STATUS = '#EF3E2D',
  GREEN_STATUS = '#16a34a',
  YELLOW_STATUS = '#fde047',
  BLUE_STATUS = '#38bdf8',
  DISABLED = '#E4DCCF',
}
