import { css } from '@emotion/react';
import PhaseBadge from './mintkitAtoms/PhaseBadge';
import ConnectButton from './mintkitAtoms/ConnectButton';
import { useStore } from '../store/store';
import MainTokenImage from './mintkitAtoms/MainTokenImage';
import { HEADER_BANNER_HEIGHT } from '../constants/shared';

const AppHeader = () => {
  const theme = useStore((s) => s.themeSlice.theme);
  return (
    <div
      css={css`
        flex: 0 1 auto;
      `}
    >
      <div
        className="relative flex px-10 transition-colors"
        css={css`
          height: ${HEADER_BANNER_HEIGHT}px;
          background: ${theme.secondary.background};
          color: ${theme.secondary.text};
        `}
      >
        <div
          className="w-full flex justify-between items-center"
          css={css`
            margin-top: ${HEADER_BANNER_HEIGHT}px;
          `}
        >
          <PhaseBadge />
          <ConnectButton />
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
