import { css } from '@emotion/react';
import { useStore } from '../../store/store';
import { Colors } from '../../themes/colors';

type NFTCountProps = {};

const NFTCount = (props: NFTCountProps) => {
  const tokenMaxSupply = useStore((s) => s.mintSlice.tokenMaxSupply);
  const tokenCurrentSupply = useStore((s) => s.mintSlice.tokenCurrentSupply);
  const tokenName = useStore((s) => s.projectSlice.tokenName);

  return (
    <div className="text-2xl py-2">
      <span
        css={css`
          ${tokenCurrentSupply < tokenMaxSupply &&
          `color: ${Colors.RED_STATUS};`}
        `}
      >
        {tokenCurrentSupply}
      </span>
      {' / '}
      <span>
        {tokenMaxSupply} {tokenName} tokens Remaining
      </span>
      <span></span>
    </div>
  );
};

export default NFTCount;
