import AppContainer from './components/AppContainer';
import { useStore } from './store/store';
import { useEffect } from 'react';
import {
  DEFAULT_WIDGET_WIDTH,
  FULL_TOKEN_WIDTH,
  ProjectPhase,
} from './constants/shared';
import SDKManager from './components/SDKManager';
import { PresentationMode } from './types/shared';
import { css } from '@emotion/react';

const App = () => {
  const isProjectLoaded = useStore((s) => s.projectSlice.isProjectLoaded);
  const projectID = useStore((s) => s.projectSlice.projectID);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const cssOverrides = useStore((s) => s.themeSlice.cssOverrides);
  const isHorizontalMode = useStore(
    (s) =>
      !!(
        s.projectSlice.appState.presentationMode ===
          PresentationMode.HORIZONTAL_IMAGE && s.projectSlice.imageUrl
      )
  );

  const fetchIsUserSignedIn = useStore((s) => s.authSlice.fetchIsUserSignedIn);
  const fetchProjectDetails = useStore(
    (s) => s.projectSlice.fetchProjectDetails
  );
  const fetchTokenName = useStore((s) => s.projectSlice.fetchTokenName);
  const fetchContractDetails = useStore(
    (s) => s.mintSlice.fetchContractDetails
  );
  const walletAddress = useStore((s) => s.userSlice.walletAddress);

  const fetchTokenTotalSupply = useStore(
    (s) => s.mintSlice.fetchTokenTotalSupply
  );
  const fetchTokenCurrentSupply = useStore(
    (s) => s.mintSlice.fetchTokenCurrentSupply
  );
  const fetchIsContractPaused = useStore(
    (s) => s.mintSlice.fetchIsContractPaused
  );
  const fetchWalletLimit = useStore((s) => s.mintSlice.fetchWalletLimit);
  const projectPhase = useStore((s) => s.projectSlice.appState.phase?.kind);

  useEffect(() => {
    if (!isProjectLoaded && projectID) {
      fetchProjectDetails();
    }

    if (!isProjectLoaded) return;
    fetchIsUserSignedIn();

    if (projectPhase === ProjectPhase.EarlyAccess) return;

    fetchTokenName();
    fetchTokenCurrentSupply();
    fetchTokenTotalSupply();
    fetchIsContractPaused();
  }, [projectID, isProjectLoaded]);

  useEffect(() => {
    if (!walletAddress || !isProjectLoaded) return;
    if (projectPhase === ProjectPhase.EarlyAccess) return;

    fetchContractDetails();
    fetchWalletLimit();
  }, [walletAddress, isProjectLoaded]);

  if (typeof isUserSignedIn === 'undefined') return null;

  return (
    <div
      id="App"
      css={css`
        overflow: hidden;
        display: flex;
        ${cssOverrides}
      `}
    >
      <SDKManager />
      <AppContainer />
    </div>
  );
};

export default App;
