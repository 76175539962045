import { Network, ProjectPhase } from '../constants/shared';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import * as UAParser from 'ua-parser-js';

export enum Events {
  walletConnected = 'walletConnected',
  walletDisconnected = 'walletDisconnected',
  messageSigned = 'messageSigned',
  transactionSent = 'transactionSent',
  contractWritten = 'contractWritten',
  initialized = 'initialized',
  signedIn = 'signedIn',
  themeOverridesApplied = 'themeOverridesApplied',
  cssOverridesApplied = 'cssOverridesApplied',
  baseThemeUpdated = 'baseThemeUpdated',
  windowDimensionsChanged = 'windowDimensionsChanged',
}

export enum Actions {
  signMessage = 'signMessage',
  sendTransaction = 'sendTransaction',
}

export type WalletConnectedPayload = {
  walletAddress?: string;
  chainID?: number;
};
export type WindowDimensionsChangedCallback = (
  dimensions: WindowDimensionsChangedPayload
) => void;
export type WindowDimensionsChangedPayload = {
  width: number;
  height: number;
};

export type WalletConnectedEventCallback = (
  walletInfo: WalletConnectedPayload
) => void;
export type WalletDisconnectedEventCallback = () => void;
export type MessageSignedEventCallback = (message: string) => void;
export type TransactionSentEventCallback = (receipt: string) => void;
export type ContractWrittenEventCallback = (
  response: WriteContractResponseType
) => void;
export type InitializedEventCallback = (data: {
  walletAddress?: string;
  chainID?: number;
  projectID: string;
  origin: string;
  url: string;
  demoMode?: boolean;
  presentationMode?: PresentationMode;
  dimensions: WindowDimensionsChangedPayload;
}) => void;
export type SignedInEventCallback = (data: {
  message: string;
  signature: string;
}) => void;

export type WalletConnectedEvent = [
  Events.walletConnected,
  WalletConnectedEventCallback
];

export type WalletDisconnectedEvent = [
  Events.walletDisconnected,
  WalletDisconnectedEventCallback
];
export type MessageSignedEvent = [
  Events.messageSigned,
  MessageSignedEventCallback
];
export type SignedInEvent = [Events.signedIn, SignedInEventCallback];
export type ContractWrittenEvent = [
  Events.contractWritten,
  ContractWrittenEventCallback
];

export type TransactionSentEvent = [
  Events.transactionSent,
  TransactionSentEventCallback
];

export type WindowDimensionsChangedEvent = [
  Events.windowDimensionsChanged,
  WindowDimensionsChangedCallback
];

export type InitializedEvent = [Events.initialized, InitializedEventCallback];

export interface PlutoSDK {
  connectWallet: () => void;
  disconnectWallet: () => void;
  signMessage: (message: string) => void;
  sendTransaction: (transaction: any) => void;
  writeContract: (contract: {
    addressOrName: string;
    contractInterface: any;
    functionName: string;
    args: any;
  }) => void;
  signIn: (data: {
    walletAddress: string;
    network: Network;
    chainID: number;
  }) => void;
  redirect: (url: string) => void;
  initialize: () => Promise<PlutoSDK>;

  on: (
    ...args:
      | WalletConnectedEvent
      | WalletDisconnectedEvent
      | MessageSignedEvent
      | TransactionSentEvent
      | ContractWrittenEvent
      | InitializedEvent
      | SignedInEvent
      | WindowDimensionsChangedEvent
  ) => void;
}

export type ProjectPhaseType = {
  contractFuncMap: {
    [key: string]: string;
  };
  id: string;
  kind: ProjectPhase;
  maxQuantityPerUser: number;
  priceInEther: string;
};

export type Contract = {
  addressOrName: string;
  contractInterface: string;
  functionName: string;
  args?: any | any[];
};

export type WriteContractResponseType = {
  contractResponse?: TransactionReceipt | Error | null;
  transactionID?: string;
};

export const ChainIDMap = {
  1: 'mainnet',
  5: 'goerli',
  11155111: 'sepolia',
  2018: 'dev',
  61: 'classic',
  63: 'mordor',
  6: 'kotti',
  212: 'astor',
  420: 'optimismgoerli',
  42161: 'arbitrum',
  421613: 'arbitrum-goerli',
};

export const MAX_DELAY_VALUE = 2147483647;

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;
export type StyleCombo = { background: Color; text: Color };

export type Theme = {
  primary: StyleCombo;
  secondary: StyleCombo;
  canvas: StyleCombo;
  missionButton: StyleCombo;
  missionBody: StyleCombo;
  fonts?: Fonts;
};

export type Fonts = {
  fontSources: string[];
  fontFamily: string;
};

export type ThemeTypes =
  | 'Pluto'
  | 'Tea'
  | 'FrogOnALog'
  | 'KoolAid'
  | 'Chrome'
  | 'Sunshine';

export enum PresentationMode {
  NO_IMAGE = 'NO_IMAGE',
  HORIZONTAL_IMAGE = 'HORIZONTAL_IMAGE',
  VERTICAL_IMAGE = 'VERTICAL_IMAGE',
}

export enum WIDTH_BREAKPOINT_TYPES {
  XXL = 'XXL',
  XL = 'XL',
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
}

export const WIDTH_BREAKPOINTS: Record<WIDTH_BREAKPOINT_TYPES, number> = {
  [WIDTH_BREAKPOINT_TYPES.XXL]: 1536,
  [WIDTH_BREAKPOINT_TYPES.XL]: 1280,
  [WIDTH_BREAKPOINT_TYPES.LG]: 1024,
  [WIDTH_BREAKPOINT_TYPES.MD]: 768,
  [WIDTH_BREAKPOINT_TYPES.SM]: 640,
};

export type UserAgent = Pick<
  UAParser.IResult,
  'browser' | 'device' | 'engine' | 'os' | 'cpu'
>;
