import { StoreApi } from 'zustand';
import axios from '../../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from '../store';
import { Missions } from '../missionSlice';
export interface RegistrationSlice {
  registerMissionsComplete: () => Promise<void>;
}

export const registrationSlice: RegistrationSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    registerMissionsComplete: async () => {
      const projectID = api.getState().projectSlice.projectID;
      await axios.post(`/projects/${projectID}/missions/completed_all`);
      api.getState().missionSlice.completeMission(Missions.RegistrationMission);
    },
  })
);
