import { css } from '@emotion/react';
import { useStore } from '../../store/store';

type HeaderProps = { headerBody: string | React.ReactNode; className?: string };

const Header = (props: HeaderProps) => {
  const theme = useStore((s) => s.themeSlice.theme);
  return (
    <div
      className={`text-4xl font-bold mb-6 select-none ${props.className || ''}`}
      css={css`
        color: ${theme.canvas.text};
      `}
    >
      {props.headerBody}
    </div>
  );
};

export default Header;
