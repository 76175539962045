import { css } from '@emotion/react';
import { useStore } from '../../store/store';
import Link from '../atoms/Link';
import { MintStatusType } from '../../store/projectSlice';
import Token from '../mintkitAtoms/Token';
import { IconX } from '@tabler/icons';
import { Colors } from '../../themes/colors';
import Button from '../atoms/Button';
import etherscan from '../../assets/images/etherscan.png';

type MintUserCompleteProps = {};

const MintUserComplete = (props: MintUserCompleteProps) => {
  const chainID = useStore((s) => s.mintSlice.contractDetails?.chainId);
  const networkPrefix =
    chainID === 5
      ? 'https://goerli.etherscan.io/tx/'
      : 'https://etherscan.io/tx/';
  const mintStatus = useStore((s) => s.projectSlice.appState.mintStatus);
  const transactionHash = mintStatus.transactionHash || '';
  const txUrl = `${networkPrefix}${transactionHash}`;
  const amountOfTokensSelected = useStore(
    (s) => s.mintSlice.amountOfTokensSelected
  );
  const fetchAllContractDetails = useStore(
    (s) => s.mintSlice.fetchAllContractDetails
  );
  const updateAppState = useStore((s) => s.projectSlice.updateAppState);

  return (
    <div className="h-full flex flex-col px-4">
      <span className="flex flex-auto items-center text-3xl flex-col justify-center text-center">
        {mintStatus.errorMessage && (
          <span
            css={css`
              color: ${Colors.RED_STATUS};
            `}
          >
            {mintStatus.errorMessage}
          </span>
        )}
        {transactionHash &&
          mintStatus.status === MintStatusType.IN_PROGRESS && (
            <Link
              className="text-xl mb-2"
              isPrimary
              label="Check the status of your transaction"
              linkedText="transaction"
              target="transaction"
              href={txUrl}
            />
          )}
      </span>

      {mintStatus.status === MintStatusType.SUCCESS && (
        <div
          css={css`
            background: ${Colors.LIGHT_GRAY};
            height: 288px;
          `}
          className="w-full flex justify-center items-center py-8 relative rounded-2xl"
        >
          <Token
            url={txUrl}
            showBorder
            css={css`
              width: 224px;
            `}
          />
          {amountOfTokensSelected > 1 && (
            <span
              className="absolute rounded-full p-4 flex items-center justify-between font-bold"
              css={css`
                background: ${Colors.DARKER_GRAY};
                color: ${Colors.WHITE};
                line-height: 16px;
                bottom: 47px;
                right: 105px;
              `}
            >
              <span
                css={css`
                  margin-top: 2px;
                `}
              >
                <IconX height={14} width={20} strokeWidth={4} />
              </span>
              {amountOfTokensSelected}
            </span>
          )}
        </div>
      )}
      <div className="flex justify-end mt-7">
        <Button
          color={Colors.GRAY}
          textColor={Colors.DARK_GRAY}
          title="Etherscan"
          transitionColor
          className="flex w-36 rounded-lg mr-6"
          onClick={() => window.open(txUrl, 'transaction')}
        >
          <img src={etherscan} width={20} height={20} className="mr-3" />
          Etherscan
        </Button>
        <Button
          color={Colors.BLACK}
          textColor={Colors.WHITE}
          onClick={() => {
            fetchAllContractDetails();
            updateAppState({ hasUserCompletedPhase: false });
          }}
          disabled={mintStatus.status === MintStatusType.IN_PROGRESS}
          title="Mint again"
          transitionColor
          className="w-28"
        >
          Mint Again
        </Button>
      </div>
    </div>
  );
};

export default MintUserComplete;
