import { OauthProvider } from '../../../store/authSlice';
import { useStore } from '../../../store/store';
import { css } from '@emotion/react';
import { Events, WalletConnectedPayload } from '../../../types/shared';
import { useEffect, useState } from 'react';
import { Network } from '../../../constants/shared';
import AuthLine from './AuthLine';
import { shortenWalletAddress } from '../../../utils/eth';
import { Colors } from '../../../themes/colors';

type AuthGateProps = {};

let userClickedConnect = false;
const AuthGate = (props: AuthGateProps) => {
  const theme = useStore((s) => s.themeSlice.theme);
  const oauth = useStore((s) => s.authSlice.oauth);
  const removeIdentity = useStore((s) => s.authSlice.removeIdentity);
  const identities = useStore((s) => s.authSlice.identities);
  const twitterHandle = identities.find(
    (i) => i.provider === OauthProvider.twitter
  )?.displayName;
  const discordHandle = identities.find(
    (i) => i.provider === OauthProvider.discord
  )?.displayName;
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const sdk = useStore((s) => s.universalSlice.sdk);
  const signOut = useStore((s) => s.authSlice.signOut);
  const walletAddress = useStore((s) => s.userSlice.walletAddress);

  const isUserFullySignedIn = isUserSignedIn && walletAddress;

  useEffect(() => {
    if (!userClickedConnect) return;

    sdk.on(Events.walletConnected, (walletInfo: WalletConnectedPayload) => {
      // We don't want the scenario where the user loads the page,
      // and we immediately pop a message on them. We want them to
      // first take an action to trigger the popup
      if (userClickedConnect) signIn(walletInfo.walletAddress);
      userClickedConnect = false;
    });
  }, [userClickedConnect]);

  const signIn = (walletAddress: string) => {
    sdk.signIn({
      walletAddress,
      network: process.env.NETWORK_ID as Network,
      chainID: 1,
    });
  };

  return (
    <div
      className="flex flex-col rounded-2xl p-6 justify-between items-center h-60"
      css={css`
        background: ${Colors.LIGHT_GRAY};
      `}
    >
      <AuthLine
        onConnect={() => {
          userClickedConnect = true;
          walletAddress ? signIn(walletAddress) : sdk.connectWallet();
        }}
        onDisconnect={() => {
          sdk.disconnectWallet();
          signOut();
        }}
        buttonConnectedText={shortenWalletAddress(walletAddress)}
        connectedText="Wallet connected"
        connectText="Connect Wallet"
        disconnectedText="Connect Wallet"
        isConnected={!!isUserFullySignedIn}
        bgColor={theme.secondary.background as Colors}
        textColor={theme.secondary.text as Colors}
      />
      <AuthLine
        onConnect={() => oauth(OauthProvider.twitter)}
        onDisconnect={() => removeIdentity(OauthProvider.twitter)}
        buttonConnectedText={`@${twitterHandle}`}
        connectedText="Twitter connected"
        connectText="Connect Twitter"
        disconnectedText="Sign in with Twitter"
        isConnected={!!twitterHandle}
        isDisabled={!isUserFullySignedIn}
        bgColor={Colors.TWITTER_BLUE}
        textColor={Colors.WHITE}
      />
      <AuthLine
        onConnect={() => oauth(OauthProvider.discord)}
        onDisconnect={() => removeIdentity(OauthProvider.discord)}
        buttonConnectedText={discordHandle}
        connectedText="Discord connected"
        connectText="Connect Discord"
        disconnectedText="Sign in with Discord"
        isConnected={!!discordHandle}
        isDisabled={!isUserFullySignedIn}
        bgColor={Colors.DISCORD_PURPLE}
        textColor={Colors.WHITE}
      />
    </div>
  );
};

export default AuthGate;
