import { css } from '@emotion/react';
import { useStore } from '../../store/store';
import NFTCount from '../mintkitAtoms/NFTCount';

type MintEndedProps = {};

const MintEnded = (props: MintEndedProps) => {
  const theme = useStore((s) => s.themeSlice.theme);

  return (
    <div className="h-full flex flex-col py-8 px-4">
      <h2 className="text-5xl mb-2">Mint is over</h2>
      <NFTCount />
    </div>
  );
};

export default MintEnded;
