import React from 'react';
import { css } from '@emotion/react';
import { Button as MantineButton } from '@mantine/core';
import { darken, lighten } from 'polished';
import { Colors } from '../../themes/colors';

type ButtonProps = {
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  title?: string;
  color: Colors;
  textColor?: Colors;
  transitionColor?: boolean;
};

const Button = (props: ButtonProps) => {
  const colorTransformationFunction =
    props.color === Colors.WHITE ? darken : lighten;
  const textColorTransformationFunction =
    props.textColor === Colors.WHITE ? darken : lighten;
  return (
    <MantineButton
      disabled={props.disabled}
      onClick={props.onClick}
      title={props.title}
      css={css`
        font-size: 0.875rem;
        line-height: 16px;
        font-weight: bold;
        ${props.textColor && `color: ${props.textColor};`};
        ${!props.onClick && 'cursor: default;'};

        background-color: ${props.color};
        transition-property: ${props.transitionColor
          ? 'width background-color'
          : 'width'};
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        &:hover {
          background-color: ${colorTransformationFunction(0.025, props.color)};
        }

        &:active {
          ${!props.onClick && 'transform: none;'}
          background-color: ${colorTransformationFunction(0.1, props.color)};
        }

        &:disabled {
          ${props.textColor &&
          `color: ${textColorTransformationFunction(0.3, props.textColor)};`};
          background-color: ${colorTransformationFunction(0.3, props.color)};
          cursor: not-allowed;

          &:hover,
          &:active {
            ${props.textColor &&
            `color: ${textColorTransformationFunction(0.3, props.textColor)};`};
            background-color: ${colorTransformationFunction(0.3, props.color)};
            cursor: not-allowed;
          }
        }

        ${!props.onClick &&
        `&:hover, &:active, &:disabled {
          background: ${props.color};
        }`}
      `}
      type="button"
      className={`inline-flex px-4 h-10 items-center border border-transparent select-none text-xs font-medium rounded-full justify-center overflow-hidden ${
        props.className || ''
      }`}
    >
      {props.children}
    </MantineButton>
  );
};

Button.defaultProps = {
  transitionColor: true,
};

export default Button;
