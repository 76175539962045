import { css } from '@emotion/react';
import congratsImg from '../../../../assets/images/congrats.png';
import { useStore } from '../../../../store/store';

const EarlyAccessUserComplete = () => {
  const tokenName = useStore((s) => s.projectSlice.tokenName);
  return (
    <div className="flex flex-col p-8">
      <h2 className="text-3xl mb-8">
        Congrats! You're registered for the {tokenName} drop
      </h2>
      <span className="flex justify-between flex-auto items-center h-full ">
        <img
          className="w-1/3 rounded-full mr-2 shadow-md p-2"
          css={css`
            background: rgba(0, 0, 0, 0.04);
          `}
          src={congratsImg}
        />
        <img
          className="w-1/3 rounded-full mr-2 shadow-md p-2"
          css={css`
            background: rgba(0, 0, 0, 0.04);
          `}
          src={congratsImg}
        />
        <img
          className="w-1/3 rounded-full mr-2 shadow-md p-2"
          css={css`
            background: rgba(0, 0, 0, 0.04);
          `}
          src={congratsImg}
        />
      </span>
    </div>
  );
};

export default EarlyAccessUserComplete;
