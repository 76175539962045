import {
  DefaultMantineColor,
  Loader as MantineLoader,
  MantineSize,
  MANTINE_SIZES,
} from '@mantine/core';

const Loader = ({
  size,
  color,
}: {
  size: MantineSize | number;
  color?: DefaultMantineColor;
}) => <MantineLoader size={size} color={color} />;

Loader.defaultProps = {
  size: MANTINE_SIZES[2],
};

export default Loader;
