import { Disclosure } from '@headlessui/react';
import { Missions } from '../../../../../../store/missionSlice';
import { useStore } from '../../../../../../store/store';
import { css } from '@emotion/react';
import { IconPlus } from '@tabler/icons';
import { Colors } from '../../../../../../themes/colors';
import { darken, lighten } from 'polished';

type DisclosureButtonProps = {
  mission: Missions;
  name: string;
};

const DisclosureButton = (props: DisclosureButtonProps) => {
  const mission = useStore((s) => s.missionSlice.missions[props.mission]);
  const isCurrentMissionSelected = useStore(
    (s) => s.missionSlice.currentlySelectedMission === mission.id
  );
  const enabledMissions = useStore((s) => s.missionSlice.enabledMissions);
  const theme = useStore((s) => s.themeSlice.theme);

  const setCurrentlyActiveMission = useStore(
    (s) => s.missionSlice.setCurrentlySelectedMission
  );

  return (
    <dt
      css={css`
        ${isCurrentMissionSelected &&
        'box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16)'};
      `}
      className="relative"
    >
      <Disclosure.Button
        className="p-3 w-full flex items-center justify-between h-20 transition-colors"
        css={css`
          border-bottom: 1px solid #e5e5e5;
          background: ${theme.missionButton.background};

          ${mission.id === Missions.RegistrationMission &&
          'border-bottom: none;'}

          &:hover {
            background: ${darken(0.025, theme.missionButton.background)};
          }
          &:active {
            background: ${darken(0.05, theme.missionButton.background)};
          }
        `}
        onClick={() =>
          setCurrentlyActiveMission(
            isCurrentMissionSelected ? null : mission.id
          )
        }
      >
        <div className="flex items-center">
          <div
            className="w-12 h-12 flex items-center justify-center font-bold rounded-full transition-colors"
            css={css`
              min-height: 3rem;
              min-width: 3rem;
              background: ${theme.primary.background};
              color: ${theme.primary.text};
            `}
          >
            {enabledMissions.indexOf(mission.id) + 1}
          </div>
          <span
            className="font-bold text-sm md:text-base ml-3"
            css={css`
              color: ${theme.missionButton.text};
            `}
          >
            {props.name}
          </span>
        </div>
        <div
          className="w-12 h-12 flex items-center justify-center font-bold rounded-full"
          css={css`
            background: ${Colors.LIGHT_GRAY};
            min-height: 3rem;
            min-width: 3rem;
          `}
        >
          <IconPlus
            className={`transition-transform ${
              isCurrentMissionSelected ? 'rotate-45' : ''
            }`}
          />
        </div>
      </Disclosure.Button>
    </dt>
  );
};

export default DisclosureButton;
