import { css } from '@emotion/react';
import { PLUTO_TOKEN_URL } from '../../constants/shared';
import { useStore } from '../../store/store';
import { Colors } from '../../themes/colors';

type TokenProps = { showBorder?: boolean; className?: string; url?: string };

const Token = (props: TokenProps) => {
  const adminUploadedImage = useStore((s) => s.projectSlice.imageUrl);
  return (
    <img
      onClick={() => {
        if (!props.url) return;
        window.open(props.url, 'etherscan');
      }}
      src={adminUploadedImage || PLUTO_TOKEN_URL}
      className={`rounded-2xl ${props.className || ''} object-contain`}
      css={css`
        ${props.showBorder &&
        `border: 4px solid ${Colors.WHITE};
        box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.16);`}
      `}
    />
  );
};

export default Token;
