// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "users.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message User
 */
export interface User {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated User.Identity identities = 5;
     */
    identities: User_Identity[];
    /**
     * @generated from protobuf field: repeated User.Tag tags = 6;
     */
    tags: User_Tag[];
    /**
     * @generated from protobuf field: repeated User.Phase phases = 7;
     */
    phases: User_Phase[];
}
/**
 * @generated from protobuf message User.Identity
 */
export interface User_Identity {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string provider = 3;
     */
    provider: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: bool verified = 4;
     */
    verified: boolean;
}
/**
 * @generated from protobuf message User.Tag
 */
export interface User_Tag {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string created_at = 3;
     */
    createdAt: string;
    /**
     * @generated from protobuf field: string updated_at = 4;
     */
    updatedAt: string;
}
/**
 * @generated from protobuf message User.Phase
 */
export interface User_Phase {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bool completed = 5;
     */
    completed: boolean;
    /**
     * @generated from protobuf field: string completed_at = 3;
     */
    completedAt: string; // maps to user_phases.created_at
    /**
     * @generated from protobuf field: repeated User.Phase.Mission missions = 4;
     */
    missions: User_Phase_Mission[];
}
/**
 * @generated from protobuf message User.Phase.Mission
 */
export interface User_Phase_Mission {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
    /**
     * @generated from protobuf field: string completed_at = 3;
     */
    completedAt: string; // maps to user_missions.completed_at
    /**
     * @generated from protobuf field: bool completed = 4;
     */
    completed: boolean;
}
/**
 * @generated from protobuf message PublicUser
 */
export interface PublicUser {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * all_missions_completed is true if a previous call to
     * POST /projects/:id/missions/completed_all succeeded
     *
     * @generated from protobuf field: bool all_missions_completed = 4;
     */
    allMissionsCompleted: boolean;
    /**
     * is_eligible is true if user has tag that is also
     * attached to phase
     *
     * @generated from protobuf field: bool is_eligible = 5;
     */
    isEligible: boolean;
    /**
     * @generated from protobuf field: repeated PublicUser.Identity identities = 6;
     */
    identities: PublicUser_Identity[];
}
/**
 * @generated from protobuf message PublicUser.Identity
 */
export interface PublicUser_Identity {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string provider = 3;
     */
    provider: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message UserErrors
 */
export interface UserErrors {
    /**
     * @generated from protobuf field: optional string id = 1;
     */
    id?: string;
    /**
     * @generated from protobuf field: optional string project_id = 2;
     */
    projectId?: string;
    /**
     * @generated from protobuf field: optional string name = 3;
     */
    name?: string;
}
/**
 * GET /users/:id
 *
 * @generated from protobuf message ShowUserResponse
 */
export interface ShowUserResponse {
    /**
     * @generated from protobuf field: User data = 1;
     */
    data?: User;
}
/**
 * @generated from protobuf message ShowPublicUserResponse
 */
export interface ShowPublicUserResponse {
    /**
     * @generated from protobuf field: PublicUser data = 1;
     */
    data?: PublicUser;
}
/**
 * GET /users
 *
 * @generated from protobuf message IndexUserResponse
 */
export interface IndexUserResponse {
    /**
     * @generated from protobuf field: repeated User data = 1;
     */
    data: User[];
    /**
     * @generated from protobuf field: string after = 2;
     */
    after: string;
    /**
     * @generated from protobuf field: string before = 3;
     */
    before: string;
}
/**
 * POST /users
 *
 * @generated from protobuf message NewUserRequest
 */
export interface NewUserRequest {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string tag_ids = 3;
     */
    tagIds: string[];
    /**
     * @generated from protobuf field: string eth_wallet_address = 4;
     */
    ethWalletAddress: string;
    /**
     * @generated from protobuf field: string twitter_screen_name = 5;
     */
    twitterScreenName: string;
    /**
     * @generated from protobuf field: string discord_username = 6;
     */
    discordUsername: string;
}
/**
 * @generated from protobuf message NewUserResponse
 */
export interface NewUserResponse {
    /**
     * @generated from protobuf field: User data = 1;
     */
    data?: User;
    /**
     * @generated from protobuf field: UserErrors errors = 2;
     */
    errors?: UserErrors;
}
/**
 * PUT /users/:id
 *
 * @generated from protobuf message EditUserRequest
 */
export interface EditUserRequest {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message EditUserResponse
 */
export interface EditUserResponse {
    /**
     * @generated from protobuf field: User data = 1;
     */
    data?: User;
    /**
     * @generated from protobuf field: UserErrors errors = 2;
     */
    errors?: UserErrors;
}
/**
 * DELETE /users/:id
 *
 * @generated from protobuf message DeleteUserResponse
 */
export interface DeleteUserResponse {
    /**
     * @generated from protobuf field: User data = 1;
     */
    data?: User;
    /**
     * @generated from protobuf field: UserErrors errors = 2;
     */
    errors?: UserErrors;
}
/**
 * POST /users/connect
 *
 * @generated from protobuf message ConnectRequest
 */
export interface ConnectRequest {
    /**
     * @generated from protobuf field: string chain = 1;
     */
    chain: string;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: string signature = 4;
     */
    signature: string;
    /**
     * @generated from protobuf field: string url = 5;
     */
    url: string;
}
/**
 * @generated from protobuf message ConnectResponse
 */
export interface ConnectResponse {
    /**
     * @generated from protobuf field: ConnectResponse.RequestErrors errors = 1;
     */
    errors?: ConnectResponse_RequestErrors;
}
/**
 * @generated from protobuf message ConnectResponse.RequestErrors
 */
export interface ConnectResponse_RequestErrors {
    /**
     * @generated from protobuf field: optional string chain = 1;
     */
    chain?: string;
    /**
     * @generated from protobuf field: optional string address = 2;
     */
    address?: string;
    /**
     * @generated from protobuf field: optional string signature = 3;
     */
    signature?: string;
}
/**
 * @generated from protobuf message AddTagToUserRequest
 */
export interface AddTagToUserRequest {
    /**
     * @generated from protobuf field: repeated string tag_ids = 1;
     */
    tagIds: string[];
}
/**
 * @generated from protobuf message DeleteTagFromUserRequest
 */
export interface DeleteTagFromUserRequest {
    /**
     * @generated from protobuf field: repeated string tag_ids = 1;
     */
    tagIds: string[];
}
/**
 * @generated from protobuf message ImportUsersRequest
 */
export interface ImportUsersRequest {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
    /**
     * @generated from protobuf field: repeated string tag_ids = 2;
     */
    tagIds: string[];
    /**
     * @generated from protobuf field: bool dry_run = 3;
     */
    dryRun: boolean;
    /**
     * @generated from protobuf field: bool bypass_missions = 4;
     */
    bypassMissions: boolean;
}
/**
 * @generated from protobuf message ImportUsersResponse
 */
export interface ImportUsersResponse {
    /**
     * @generated from protobuf field: repeated User success_users = 1;
     */
    successUsers: User[];
    /**
     * @generated from protobuf field: repeated string error_rows = 2;
     */
    errorRows: string[];
    /**
     * @generated from protobuf field: bool dry_run = 3;
     */
    dryRun: boolean;
}
/**
 * @generated from protobuf message UserOAuthRequest
 */
export interface UserOAuthRequest {
    /**
     * @generated from protobuf field: string final_redirect_url = 1;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message UserOAuthResponse
 */
export interface UserOAuthResponse {
    /**
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "identities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User_Identity },
            { no: 6, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User_Tag },
            { no: 7, name: "phases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User_Phase }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", projectId: "", name: "", identities: [], tags: [], phases: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* repeated User.Identity identities */ 5:
                    message.identities.push(User_Identity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated User.Tag tags */ 6:
                    message.tags.push(User_Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated User.Phase phases */ 7:
                    message.phases.push(User_Phase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* repeated User.Identity identities = 5; */
        for (let i = 0; i < message.identities.length; i++)
            User_Identity.internalBinaryWrite(message.identities[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated User.Tag tags = 6; */
        for (let i = 0; i < message.tags.length; i++)
            User_Tag.internalBinaryWrite(message.tags[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated User.Phase phases = 7; */
        for (let i = 0; i < message.phases.length; i++)
            User_Phase.internalBinaryWrite(message.phases[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Identity$Type extends MessageType<User_Identity> {
    constructor() {
        super("User.Identity", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "provider", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<User_Identity>): User_Identity {
        const message = { id: "", provider: "", displayName: "", verified: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Identity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Identity): User_Identity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string provider */ 3:
                    message.provider = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* bool verified */ 4:
                    message.verified = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Identity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string provider = 3; */
        if (message.provider !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.provider);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* bool verified = 4; */
        if (message.verified !== false)
            writer.tag(4, WireType.Varint).bool(message.verified);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User.Identity
 */
export const User_Identity = new User_Identity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Tag$Type extends MessageType<User_Tag> {
    constructor() {
        super("User.Tag", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User_Tag>): User_Tag {
        const message = { id: "", name: "", createdAt: "", updatedAt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Tag): User_Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string created_at */ 3:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 4:
                    message.updatedAt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string created_at = 3; */
        if (message.createdAt !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 4; */
        if (message.updatedAt !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.updatedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User.Tag
 */
export const User_Tag = new User_Tag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Phase$Type extends MessageType<User_Phase> {
    constructor() {
        super("User.Phase", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "completed_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "missions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User_Phase_Mission }
        ]);
    }
    create(value?: PartialMessage<User_Phase>): User_Phase {
        const message = { id: "", name: "", completed: false, completedAt: "", missions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Phase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Phase): User_Phase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool completed */ 5:
                    message.completed = reader.bool();
                    break;
                case /* string completed_at */ 3:
                    message.completedAt = reader.string();
                    break;
                case /* repeated User.Phase.Mission missions */ 4:
                    message.missions.push(User_Phase_Mission.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Phase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool completed = 5; */
        if (message.completed !== false)
            writer.tag(5, WireType.Varint).bool(message.completed);
        /* string completed_at = 3; */
        if (message.completedAt !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.completedAt);
        /* repeated User.Phase.Mission missions = 4; */
        for (let i = 0; i < message.missions.length; i++)
            User_Phase_Mission.internalBinaryWrite(message.missions[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User.Phase
 */
export const User_Phase = new User_Phase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Phase_Mission$Type extends MessageType<User_Phase_Mission> {
    constructor() {
        super("User.Phase.Mission", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "completed_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<User_Phase_Mission>): User_Phase_Mission {
        const message = { id: "", key: "", completedAt: "", completed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Phase_Mission>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Phase_Mission): User_Phase_Mission {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                case /* string completed_at */ 3:
                    message.completedAt = reader.string();
                    break;
                case /* bool completed */ 4:
                    message.completed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Phase_Mission, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        /* string completed_at = 3; */
        if (message.completedAt !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.completedAt);
        /* bool completed = 4; */
        if (message.completed !== false)
            writer.tag(4, WireType.Varint).bool(message.completed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User.Phase.Mission
 */
export const User_Phase_Mission = new User_Phase_Mission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublicUser$Type extends MessageType<PublicUser> {
    constructor() {
        super("PublicUser", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "all_missions_completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "is_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "identities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PublicUser_Identity }
        ]);
    }
    create(value?: PartialMessage<PublicUser>): PublicUser {
        const message = { id: "", projectId: "", name: "", allMissionsCompleted: false, isEligible: false, identities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PublicUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublicUser): PublicUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* bool all_missions_completed */ 4:
                    message.allMissionsCompleted = reader.bool();
                    break;
                case /* bool is_eligible */ 5:
                    message.isEligible = reader.bool();
                    break;
                case /* repeated PublicUser.Identity identities */ 6:
                    message.identities.push(PublicUser_Identity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublicUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* bool all_missions_completed = 4; */
        if (message.allMissionsCompleted !== false)
            writer.tag(4, WireType.Varint).bool(message.allMissionsCompleted);
        /* bool is_eligible = 5; */
        if (message.isEligible !== false)
            writer.tag(5, WireType.Varint).bool(message.isEligible);
        /* repeated PublicUser.Identity identities = 6; */
        for (let i = 0; i < message.identities.length; i++)
            PublicUser_Identity.internalBinaryWrite(message.identities[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PublicUser
 */
export const PublicUser = new PublicUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublicUser_Identity$Type extends MessageType<PublicUser_Identity> {
    constructor() {
        super("PublicUser.Identity", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "provider", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PublicUser_Identity>): PublicUser_Identity {
        const message = { id: "", provider: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PublicUser_Identity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublicUser_Identity): PublicUser_Identity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string provider */ 3:
                    message.provider = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublicUser_Identity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string provider = 3; */
        if (message.provider !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.provider);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PublicUser.Identity
 */
export const PublicUser_Identity = new PublicUser_Identity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserErrors$Type extends MessageType<UserErrors> {
    constructor() {
        super("UserErrors", [
            { no: 1, name: "id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserErrors>): UserErrors {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserErrors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserErrors): UserErrors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string id */ 1:
                    message.id = reader.string();
                    break;
                case /* optional string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* optional string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserErrors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string id = 1; */
        if (message.id !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* optional string project_id = 2; */
        if (message.projectId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* optional string name = 3; */
        if (message.name !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserErrors
 */
export const UserErrors = new UserErrors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowUserResponse$Type extends MessageType<ShowUserResponse> {
    constructor() {
        super("ShowUserResponse", [
            { no: 1, name: "data", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<ShowUserResponse>): ShowUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowUserResponse): ShowUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* User data */ 1:
                    message.data = User.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* User data = 1; */
        if (message.data)
            User.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowUserResponse
 */
export const ShowUserResponse = new ShowUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowPublicUserResponse$Type extends MessageType<ShowPublicUserResponse> {
    constructor() {
        super("ShowPublicUserResponse", [
            { no: 1, name: "data", kind: "message", T: () => PublicUser }
        ]);
    }
    create(value?: PartialMessage<ShowPublicUserResponse>): ShowPublicUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowPublicUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowPublicUserResponse): ShowPublicUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PublicUser data */ 1:
                    message.data = PublicUser.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowPublicUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PublicUser data = 1; */
        if (message.data)
            PublicUser.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowPublicUserResponse
 */
export const ShowPublicUserResponse = new ShowPublicUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexUserResponse$Type extends MessageType<IndexUserResponse> {
    constructor() {
        super("IndexUserResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 2, name: "after", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "before", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IndexUserResponse>): IndexUserResponse {
        const message = { data: [], after: "", before: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndexUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndexUserResponse): IndexUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated User data */ 1:
                    message.data.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string after */ 2:
                    message.after = reader.string();
                    break;
                case /* string before */ 3:
                    message.before = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndexUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated User data = 1; */
        for (let i = 0; i < message.data.length; i++)
            User.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string after = 2; */
        if (message.after !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.after);
        /* string before = 3; */
        if (message.before !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.before);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IndexUserResponse
 */
export const IndexUserResponse = new IndexUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewUserRequest$Type extends MessageType<NewUserRequest> {
    constructor() {
        super("NewUserRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "eth_wallet_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "twitter_screen_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "discord_username", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewUserRequest>): NewUserRequest {
        const message = { projectId: "", name: "", tagIds: [], ethWalletAddress: "", twitterScreenName: "", discordUsername: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewUserRequest): NewUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string tag_ids */ 3:
                    message.tagIds.push(reader.string());
                    break;
                case /* string eth_wallet_address */ 4:
                    message.ethWalletAddress = reader.string();
                    break;
                case /* string twitter_screen_name */ 5:
                    message.twitterScreenName = reader.string();
                    break;
                case /* string discord_username */ 6:
                    message.discordUsername = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string tag_ids = 3; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tagIds[i]);
        /* string eth_wallet_address = 4; */
        if (message.ethWalletAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ethWalletAddress);
        /* string twitter_screen_name = 5; */
        if (message.twitterScreenName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.twitterScreenName);
        /* string discord_username = 6; */
        if (message.discordUsername !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.discordUsername);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewUserRequest
 */
export const NewUserRequest = new NewUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewUserResponse$Type extends MessageType<NewUserResponse> {
    constructor() {
        super("NewUserResponse", [
            { no: 1, name: "data", kind: "message", T: () => User },
            { no: 2, name: "errors", kind: "message", T: () => UserErrors }
        ]);
    }
    create(value?: PartialMessage<NewUserResponse>): NewUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewUserResponse): NewUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* User data */ 1:
                    message.data = User.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* UserErrors errors */ 2:
                    message.errors = UserErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* User data = 1; */
        if (message.data)
            User.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* UserErrors errors = 2; */
        if (message.errors)
            UserErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewUserResponse
 */
export const NewUserResponse = new NewUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditUserRequest$Type extends MessageType<EditUserRequest> {
    constructor() {
        super("EditUserRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditUserRequest>): EditUserRequest {
        const message = { projectId: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditUserRequest): EditUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditUserRequest
 */
export const EditUserRequest = new EditUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditUserResponse$Type extends MessageType<EditUserResponse> {
    constructor() {
        super("EditUserResponse", [
            { no: 1, name: "data", kind: "message", T: () => User },
            { no: 2, name: "errors", kind: "message", T: () => UserErrors }
        ]);
    }
    create(value?: PartialMessage<EditUserResponse>): EditUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditUserResponse): EditUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* User data */ 1:
                    message.data = User.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* UserErrors errors */ 2:
                    message.errors = UserErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* User data = 1; */
        if (message.data)
            User.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* UserErrors errors = 2; */
        if (message.errors)
            UserErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditUserResponse
 */
export const EditUserResponse = new EditUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteUserResponse$Type extends MessageType<DeleteUserResponse> {
    constructor() {
        super("DeleteUserResponse", [
            { no: 1, name: "data", kind: "message", T: () => User },
            { no: 2, name: "errors", kind: "message", T: () => UserErrors }
        ]);
    }
    create(value?: PartialMessage<DeleteUserResponse>): DeleteUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteUserResponse): DeleteUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* User data */ 1:
                    message.data = User.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* UserErrors errors */ 2:
                    message.errors = UserErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* User data = 1; */
        if (message.data)
            User.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* UserErrors errors = 2; */
        if (message.errors)
            UserErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteUserResponse
 */
export const DeleteUserResponse = new DeleteUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectRequest$Type extends MessageType<ConnectRequest> {
    constructor() {
        super("ConnectRequest", [
            { no: 1, name: "chain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectRequest>): ConnectRequest {
        const message = { chain: "", address: "", message: "", signature: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConnectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectRequest): ConnectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string chain */ 1:
                    message.chain = reader.string();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* string signature */ 4:
                    message.signature = reader.string();
                    break;
                case /* string url */ 5:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string chain = 1; */
        if (message.chain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.chain);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* string signature = 4; */
        if (message.signature !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.signature);
        /* string url = 5; */
        if (message.url !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConnectRequest
 */
export const ConnectRequest = new ConnectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectResponse$Type extends MessageType<ConnectResponse> {
    constructor() {
        super("ConnectResponse", [
            { no: 1, name: "errors", kind: "message", T: () => ConnectResponse_RequestErrors }
        ]);
    }
    create(value?: PartialMessage<ConnectResponse>): ConnectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConnectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectResponse): ConnectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ConnectResponse.RequestErrors errors */ 1:
                    message.errors = ConnectResponse_RequestErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ConnectResponse.RequestErrors errors = 1; */
        if (message.errors)
            ConnectResponse_RequestErrors.internalBinaryWrite(message.errors, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConnectResponse
 */
export const ConnectResponse = new ConnectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectResponse_RequestErrors$Type extends MessageType<ConnectResponse_RequestErrors> {
    constructor() {
        super("ConnectResponse.RequestErrors", [
            { no: 1, name: "chain", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "signature", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectResponse_RequestErrors>): ConnectResponse_RequestErrors {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConnectResponse_RequestErrors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectResponse_RequestErrors): ConnectResponse_RequestErrors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string chain */ 1:
                    message.chain = reader.string();
                    break;
                case /* optional string address */ 2:
                    message.address = reader.string();
                    break;
                case /* optional string signature */ 3:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectResponse_RequestErrors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string chain = 1; */
        if (message.chain !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.chain);
        /* optional string address = 2; */
        if (message.address !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* optional string signature = 3; */
        if (message.signature !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConnectResponse.RequestErrors
 */
export const ConnectResponse_RequestErrors = new ConnectResponse_RequestErrors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTagToUserRequest$Type extends MessageType<AddTagToUserRequest> {
    constructor() {
        super("AddTagToUserRequest", [
            { no: 1, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddTagToUserRequest>): AddTagToUserRequest {
        const message = { tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddTagToUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTagToUserRequest): AddTagToUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string tag_ids */ 1:
                    message.tagIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTagToUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string tag_ids = 1; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.tagIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AddTagToUserRequest
 */
export const AddTagToUserRequest = new AddTagToUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTagFromUserRequest$Type extends MessageType<DeleteTagFromUserRequest> {
    constructor() {
        super("DeleteTagFromUserRequest", [
            { no: 1, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteTagFromUserRequest>): DeleteTagFromUserRequest {
        const message = { tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteTagFromUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTagFromUserRequest): DeleteTagFromUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string tag_ids */ 1:
                    message.tagIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTagFromUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string tag_ids = 1; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.tagIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteTagFromUserRequest
 */
export const DeleteTagFromUserRequest = new DeleteTagFromUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportUsersRequest$Type extends MessageType<ImportUsersRequest> {
    constructor() {
        super("ImportUsersRequest", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "dry_run", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "bypass_missions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ImportUsersRequest>): ImportUsersRequest {
        const message = { data: "", tagIds: [], dryRun: false, bypassMissions: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImportUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImportUsersRequest): ImportUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                case /* repeated string tag_ids */ 2:
                    message.tagIds.push(reader.string());
                    break;
                case /* bool dry_run */ 3:
                    message.dryRun = reader.bool();
                    break;
                case /* bool bypass_missions */ 4:
                    message.bypassMissions = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImportUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        /* repeated string tag_ids = 2; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.tagIds[i]);
        /* bool dry_run = 3; */
        if (message.dryRun !== false)
            writer.tag(3, WireType.Varint).bool(message.dryRun);
        /* bool bypass_missions = 4; */
        if (message.bypassMissions !== false)
            writer.tag(4, WireType.Varint).bool(message.bypassMissions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ImportUsersRequest
 */
export const ImportUsersRequest = new ImportUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportUsersResponse$Type extends MessageType<ImportUsersResponse> {
    constructor() {
        super("ImportUsersResponse", [
            { no: 1, name: "success_users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 2, name: "error_rows", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "dry_run", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ImportUsersResponse>): ImportUsersResponse {
        const message = { successUsers: [], errorRows: [], dryRun: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImportUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImportUsersResponse): ImportUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated User success_users */ 1:
                    message.successUsers.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string error_rows */ 2:
                    message.errorRows.push(reader.string());
                    break;
                case /* bool dry_run */ 3:
                    message.dryRun = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImportUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated User success_users = 1; */
        for (let i = 0; i < message.successUsers.length; i++)
            User.internalBinaryWrite(message.successUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string error_rows = 2; */
        for (let i = 0; i < message.errorRows.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.errorRows[i]);
        /* bool dry_run = 3; */
        if (message.dryRun !== false)
            writer.tag(3, WireType.Varint).bool(message.dryRun);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ImportUsersResponse
 */
export const ImportUsersResponse = new ImportUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserOAuthRequest$Type extends MessageType<UserOAuthRequest> {
    constructor() {
        super("UserOAuthRequest", [
            { no: 1, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserOAuthRequest>): UserOAuthRequest {
        const message = { finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserOAuthRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserOAuthRequest): UserOAuthRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string final_redirect_url */ 1:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserOAuthRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string final_redirect_url = 1; */
        if (message.finalRedirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserOAuthRequest
 */
export const UserOAuthRequest = new UserOAuthRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserOAuthResponse$Type extends MessageType<UserOAuthResponse> {
    constructor() {
        super("UserOAuthResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserOAuthResponse>): UserOAuthResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserOAuthResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserOAuthResponse): UserOAuthResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserOAuthResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserOAuthResponse
 */
export const UserOAuthResponse = new UserOAuthResponse$Type();
