import axios from '../async/axios';
import * as ProjectsProto from '../async/proto/projects/projects';
import { lens } from '@dhmk/zustand-lens';
import { PhaseKey, ProjectPhase } from '../constants/shared';
import { StoreApi } from 'zustand';
import { requireProjectID, Store } from './store';
import { PresentationMode } from '../types/shared';

export enum MintStatusType {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type MintStatus = {
  status: MintStatusType;
  transactionHash?: string;
  errorMessage?: string;
};

type AppState = {
  phase?: ProjectsProto.PublicProject_Phase;
  phaseKey?: PhaseKey;
  isUserEligibleToMint: boolean;
  hasUserCompletedPhase: boolean;
  isAuthRequired: boolean;
  mintStatus: MintStatus;
  demoMode?: boolean;
  presentationMode: PresentationMode;
};

export interface ProjectSlice {
  projectID?: string;
  isProjectLoaded: boolean;
  fetchProjectDetails: () => Promise<void>;
  fetchTokenName: () => Promise<void>;
  resetAppState: () => void;
  updateProjectID: (projectID: string) => void;
  updateProjectPhase: (phase: ProjectPhase) => void;
  updateProjectPhaseKey: (phaseKey: PhaseKey) => void;
  updateMintStatus: (mintStatus: Partial<MintStatus>) => void;
  updateAppState: (appState: Partial<AppState>) => void;
  appState: AppState;
  tokenName: string;
  imageUrl: string;
}

const defaultAppState = {
  phase: undefined,
  phaseKey: undefined,
  areAllMissionsCompleted: false,
  isUserEligibleToMint: false,
  hasUserCompletedPhase: false,
  isAuthRequired: true,
  mintStatus: {
    status: MintStatusType.NOT_STARTED,
  },
  presentationMode: PresentationMode.NO_IMAGE,
};

export const projectSlice: ProjectSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    projectID: undefined,
    isProjectLoaded: false,
    tokenName: '',
    imageUrl: '',
    appState: defaultAppState,
    fetchTokenName: async () => {
      const projectID = requireProjectID();
      const response = await axios.get(
        `/projects/${projectID}/contract?function=name&call=true`
      );
      const { jsonOutputs } = ProjectsProto.ContractCallResponse.fromJson(
        response.data
      );

      const parsedValue = JSON.parse(jsonOutputs)[0];
      setState({
        tokenName: parsedValue.value || '',
      });
    },
    fetchProjectDetails: async () => {
      const projectID = getState().projectID;
      if (!projectID) return;

      const response = await axios.get(`/projects/${projectID}/embed`);
      const { phase, phaseKey, id, imageUrl } =
        ProjectsProto.ShowPublicProjectResponse.fromJson(response.data).data;

      const projectPhase = phase
        ? { ...(phase as ProjectsProto.PublicProject_Phase) }
        : undefined;

      const isAuthRequired = ![
        ProjectPhase.ProtectedPublicMint,
        ProjectPhase.PublicMint,
      ].includes(projectPhase?.kind as ProjectPhase);

      setState({
        isProjectLoaded: true,
        projectID: id,
        imageUrl,

        appState: {
          ...getState().appState,
          phase: projectPhase,
          phaseKey: phaseKey as PhaseKey,
          isAuthRequired,
        },
      });

      api.getState().universalSlice.hydrateWidgetDimensions();
    },
    updateProjectID: (projectID: string) =>
      setState({
        projectID,
        isProjectLoaded: projectID === getState().projectID,
      }),
    updateProjectPhase: (projectPhase: ProjectPhase) =>
      setState({
        appState: {
          ...getState().appState,
          phase: { ...getState().appState.phase, kind: projectPhase },
        },
      }),
    updateProjectPhaseKey: (projectPhaseKey: PhaseKey) => {
      setState({
        appState: { ...getState().appState, phaseKey: projectPhaseKey },
      });
    },
    updateMintStatus: (mintStatus: Partial<MintStatus>) => {
      setState({
        appState: {
          ...getState().appState,
          mintStatus: { ...getState().appState.mintStatus, ...mintStatus },
        },
      });
    },
    updateAppState: (appState: Partial<AppState>) =>
      setState({
        appState: { ...getState().appState, ...appState },
      }),
    resetAppState: () => setState({ appState: { ...defaultAppState } }),
  })
);
