import AmountSelector from './AmountSelector';
import NFTCount from '../mintkitAtoms/NFTCount';
import MintFooter from './MintFooter';
import { useStore } from '../../store/store';
import Notification from '../mintkitAtoms/Notification';
import { NotificationLevels } from '../../enums';
import { ChainIDMap, MAX_DELAY_VALUE } from '../../types/shared';
import { useEffect } from 'react';

type MintActiveProps = {};

const MintActive = (props: MintActiveProps) => {
  const contractChainID = useStore((s) => s.mintSlice.contractDetails?.chainId);
  const walletChainID = useStore((s) => s.userSlice.chainID);
  const shouldShowNetworkBanner =
    typeof contractChainID === 'number' &&
    typeof walletChainID === 'number' &&
    contractChainID !== walletChainID;
  const notify = useStore((s) => s.notificationSlice.notify);
  const dismiss = useStore((s) => s.notificationSlice.dismiss);
  useEffect(() => {
    if (shouldShowNetworkBanner) {
      notify({
        level: NotificationLevels.WARNING,
        id: 'networkMismatch',
        body: `Contract network is ${ChainIDMap[contractChainID]}. Wallet network is ${ChainIDMap[walletChainID]}. You must use the ${ChainIDMap[contractChainID]} network to mint.`,
        duration: MAX_DELAY_VALUE,
        isCloseable: false,
      });
    } else {
      dismiss('networkMismatch');
    }
  }, [shouldShowNetworkBanner]);

  return (
    <div className="flex flex-col justify-between h-full mb-2">
      <div className="flex-auto flex flex-col justify-start">
        {/* {shouldShowNetworkBanner && (
          <Notification
            className="fixed top-0 right-0"
            notification={}
          />
        )} */}
        {/* <NFTCount /> */}
        <AmountSelector />
      </div>
      <MintFooter />
    </div>
  );
};

export default MintActive;
