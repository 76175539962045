import { css } from '@emotion/react';
import { IconCheck } from '@tabler/icons';
import { useStore } from '../../../store/store';
import { Colors } from '../../../themes/colors';
import AuthButton from '../../mintkitAtoms/AuthButton';

type AuthLineProps = {
  disconnectedText: string;
  connectedText: string;
  buttonConnectedText: string;
  connectText: string;
  onConnect: () => void;
  onDisconnect: () => void;
  isConnected: boolean;
  className?: string;
  isDisabled?: boolean;
  bgColor: Colors;
  textColor: Colors;
};

const AuthLine = (props: AuthLineProps) => {
  const theme = useStore((s) => s.themeSlice.theme);
  return (
    <div className="flex justify-between w-full items-center">
      <div className="flex items-center">
        <span
          className="rounded-full flex justify-center items-center mr-4 transition-colors"
          css={css`
            min-width: 3rem;
            min-height: 3rem;
            background: ${theme.primary.background};
            color: ${theme.primary.text};

            ${!props.isConnected &&
            `
              background: ${Colors.WHITE};
              color: ${Colors.DARK_GRAY}
              border: 2px solid ${Colors.GRAY};
            `}
          `}
        >
          {props.isConnected && (
            <IconCheck
              className="w-6 h-6"
              css={css`
                stroke-width: 3px;
              `}
            />
          )}
        </span>
        {props.isConnected ? props.connectedText : props.connectText}
      </div>
      <AuthButton
        onConnect={props.onConnect}
        onDisconnect={props.onDisconnect}
        connectedText={props.buttonConnectedText}
        disconnectedText={props.disconnectedText}
        isConnected={props.isConnected}
        bgColor={props.bgColor}
        textColor={props.textColor}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default AuthLine;
