declare var Pluto: any;

import { useStore } from '../store/store';
import {
  ContractWrittenEventCallback,
  Events,
  InitializedEventCallback,
  MessageSignedEventCallback,
  SignedInEventCallback,
  Theme,
  TransactionSentEventCallback,
  WalletConnectedEventCallback,
  WalletConnectedPayload,
  WalletDisconnectedEventCallback,
  WindowDimensionsChangedPayload,
  WriteContractResponseType,
} from '../types/shared';
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Network, Themes as ThemeTypes } from '../constants/shared';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { MintStatusType } from '../store/projectSlice';

const SDKManager = () => {
  const updateProjectID = useStore((s) => s.projectSlice.updateProjectID);
  const updateWalletAddress = useStore((s) => s.userSlice.updateWalletAddress);
  const updateChainID = useStore((s) => s.userSlice.updateChainID);
  const updateHostOrigin = useStore((s) => s.universalSlice.updateHostOrigin);
  const applyThemeOverrides = useStore((s) => s.themeSlice.applyThemeOverrides);
  const applyCSSOverrides = useStore((s) => s.themeSlice.applyCSSOverrides);
  const fetchIsUserSignedIn = useStore((s) => s.authSlice.fetchIsUserSignedIn);
  const updateBaseTheme = useStore((s) => s.themeSlice.updateTheme);
  const updateAppState = useStore((s) => s.projectSlice.updateAppState);
  const resetAppState = useStore((s) => s.projectSlice.resetAppState);
  const updateMintStatus = useStore((s) => s.projectSlice.updateMintStatus);
  const setSDK = useStore((s) => s.universalSlice.setSDK);
  const signIn = useStore((s) => s.authSlice.signIn);
  const signOut = useStore((s) => s.authSlice.signOut);
  const updateUrl = useStore((s) => s.universalSlice.updateUrl);
  const updateWindowDimensions = useStore(
    (s) => s.universalSlice.updateWindowDimensions
  );

  useEffect(() => {
    setSDK(Pluto);

    Pluto.on(Events.walletConnected, ((walletInfo: WalletConnectedPayload) => {
      const { walletAddress: newAddress, chainID } = walletInfo;
      // We get the fresh state rather than using a hook because
      // when using callbacks in a render cycle, there is a chance
      // the data is stale
      const walletAddress = useStore.getState().userSlice.walletAddress;
      const sdk = useStore.getState().universalSlice.sdk;
      console.debug('Wallet Connected', newAddress);

      if (walletAddress && newAddress && walletAddress !== newAddress) {
        sdk.signIn({
          walletAddress: newAddress,
          network: process.env.NETWORK_ID as Network,
          chainID: 1,
        });
      }
      updateWalletAddress(newAddress);
      updateChainID(chainID);
    }) as WalletConnectedEventCallback);
    Pluto.on(Events.walletDisconnected, (() => {
      console.debug('Wallet Disconnected');
      updateWalletAddress(null);
      updateChainID(null);
      signOut();
    }) as WalletDisconnectedEventCallback);
    Pluto.on(Events.messageSigned, ((message: string) => {
      console.debug('Message Signed', message);
    }) as MessageSignedEventCallback);
    Pluto.on(Events.transactionSent, ((receipt: string) => {
      console.debug(`Transaction Sent`, receipt);
    }) as TransactionSentEventCallback);
    Pluto.on(Events.contractWritten, ((response: WriteContractResponseType) => {
      if (!response?.contractResponse && !response?.transactionID) {
        console.error('Contract failed to write');
        updateMintStatus({
          status: MintStatusType.FAILED,
          transactionHash: response.transactionID,
          errorMessage: 'An unknown error has occurred.',
        });
      }
      if (!response?.contractResponse) {
        console.debug(
          `Contract Transaction initiated`,
          response?.transactionID
        );
        updateAppState({
          hasUserCompletedPhase: true,
        });
        updateMintStatus({
          status: MintStatusType.IN_PROGRESS,
          transactionHash: response.transactionID,
          errorMessage: undefined,
        });
      } else if ('transactionHash' in response?.contractResponse) {
        const receipt = response.contractResponse as TransactionReceipt;
        console.debug(`Contract Written: `, receipt.transactionHash);
        updateMintStatus({
          status: MintStatusType.SUCCESS,
          transactionHash: response.transactionID,
          errorMessage: undefined,
        });
      } else {
        const err = response.contractResponse as Error;
        console.debug(`Contract failed to write: `, err?.message);
        updateMintStatus({
          status: MintStatusType.FAILED,
          errorMessage: err?.message,
          transactionHash: response.transactionID,
        });
      }
    }) as ContractWrittenEventCallback);
    Pluto.on(Events.initialized, ((data) => {
      console.debug('Initialized', data);
      resetAppState();
      updateProjectID(data.projectID);
      updateChainID(data.chainID);
      updateWalletAddress(data.walletAddress);
      updateHostOrigin(data.origin);
      updateUrl(data.url);
      if (typeof data.demoMode !== 'undefined') {
        updateAppState({ demoMode: data.demoMode });
      }
      if (typeof data.presentationMode !== 'undefined') {
        updateAppState({ presentationMode: data.presentationMode });
      }

      updateWindowDimensions(data.dimensions);
    }) as InitializedEventCallback);
    Pluto.on(Events.signedIn, (async ({ signature, message }) => {
      console.debug('Signed In', { signature, message });
      await signIn(message, signature);
      await fetchIsUserSignedIn();
    }) as SignedInEventCallback);
    Pluto.on(Events.themeOverridesApplied, (theme: Partial<Theme>) => {
      console.debug('Theme Changed', theme);
      applyThemeOverrides(theme);
    });
    Pluto.on(
      Events.windowDimensionsChanged,
      (dimensions: WindowDimensionsChangedPayload) => {
        updateWindowDimensions(dimensions);
      }
    );
    Pluto.on(Events.cssOverridesApplied, (cssOverrides: string) => {
      console.debug('CSS Overrides Being Applied', cssOverrides);
      applyCSSOverrides(cssOverrides);
    });
    Pluto.on(Events.baseThemeUpdated, (baseTheme: ThemeTypes) => {
      console.debug(`Base Theme Updated`, baseTheme);
      updateBaseTheme(baseTheme);
    });

    Pluto.initialize();
  }, []);

  return <div css={css``}></div>;
};

export default SDKManager;
