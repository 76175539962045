import { notificationSlice, NotificationSlice } from './notificationSlice';
import {
  RegistrationSlice,
  registrationSlice,
} from './missions/registrationSlice';
import create from 'zustand';
import debounce from 'lodash/debounce';
import { authSlice, AuthSlice } from './authSlice';
import { missionSlice, MissionSlice } from './missionSlice';
import { discordSlice, DiscordSlice } from './missions/discordSlice';
import { projectSlice, ProjectSlice } from './projectSlice';
import { themeSlice, ThemeSlice } from './themeSlice';
import { userSlice, UserSlice } from './userSlice';
import { UniversalSlice, universalSlice } from './universalSlice';
import { withLenses } from '@dhmk/zustand-lens';
import { TwitterSlice, twitterSlice } from './missions/twitterSlice';
import { MintSlice, mintSlice } from './mintSlice';
import { add } from 'lodash';

export type Store = {
  authSlice: AuthSlice;
  discordSlice: DiscordSlice;
  twitterSlice: TwitterSlice;
  missionSlice: MissionSlice;
  projectSlice: ProjectSlice;
  themeSlice: ThemeSlice;
  universalSlice: UniversalSlice;
  userSlice: UserSlice;
  registrationSlice: RegistrationSlice;
  mintSlice: MintSlice;
  notificationSlice: NotificationSlice;
};

const useStore = create<Store>(
  withLenses({
    universalSlice,
    authSlice,
    userSlice,
    themeSlice,
    missionSlice,
    discordSlice,
    projectSlice,
    notificationSlice,
    registrationSlice,
    twitterSlice,
    mintSlice,
  })
);

useStore.subscribe(
  debounce((state) => {
    window.PL = state;
  }, 1000)
);

window.PL = useStore.getState();

export const requireProjectID = () => {
  const projectID = useStore.getState().projectSlice.projectID;
  if (!projectID) {
    throw new Error('Attempted to call endpoint with no Project ID');
  }

  return projectID;
};

export const requireUserID = () => {
  const userID = useStore.getState().authSlice.userID;
  if (!userID) {
    throw new Error('Attempted to call endpoint with no user id');
  }
  return userID;
};

export const requireWalletAddress = () => {
  const walletAddress = useStore.getState().userSlice.walletAddress;
  if (!walletAddress) {
    throw new Error('Attempted to call endpoint with no Wallet Address');
  }

  return walletAddress;
};

export const normalizeWalletAddress = (walletAddress: string) => {
  const network = process.env.NETWORK_ID;
  if (walletAddress.includes(`${network}://`)) return walletAddress;

  return `${network}://${walletAddress}`;
};

export const trimAddress = (address?: string) => {
  if (!address) return '';
  if (address.includes('//')) {
    return address.split('//')[1];
  }

  return address;
};

export { useStore };
