import { css } from '@emotion/react';

const EarlyAccessEnded = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 2rem;
        color: white; important!
      `}
    >
      Registration has completed for this project
    </div>
  );
};

export default EarlyAccessEnded;
