// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "discord.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message NewDiscordMissionRequest
 */
export interface NewDiscordMissionRequest {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string final_redirect_url = 2;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message NewDiscordMissionResponse
 */
export interface NewDiscordMissionResponse {
    /**
     * @generated from protobuf field: string mission_id = 1;
     */
    missionId: string;
    /**
     * @generated from protobuf field: string redirect_url = 2;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message ReauthDiscordMissionRequest
 */
export interface ReauthDiscordMissionRequest {
    /**
     * @generated from protobuf field: string final_redirect_url = 1;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message ReauthDiscordMissionResponse
 */
export interface ReauthDiscordMissionResponse {
    /**
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message CompleteDiscordMissionRequest
 */
export interface CompleteDiscordMissionRequest {
    /**
     * @generated from protobuf field: string mission_id = 1;
     */
    missionId: string;
    /**
     * @generated from protobuf field: string final_redirect_url = 2;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message CompleteDiscordMissionResponse
 */
export interface CompleteDiscordMissionResponse {
    /**
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message ShowDiscordMissionResponse
 */
export interface ShowDiscordMissionResponse {
    /**
     * @generated from protobuf field: repeated DiscordGuild guilds = 1;
     */
    guilds: DiscordGuild[];
    /**
     * @generated from protobuf field: string guild_id = 2;
     */
    guildId: string;
    /**
     * @generated from protobuf field: string role_id = 3;
     */
    roleId: string;
    /**
     * @generated from protobuf field: string invite_link = 4;
     */
    inviteLink: string;
    /**
     * @generated from protobuf field: bool ready = 5;
     */
    ready: boolean;
}
/**
 * @generated from protobuf message DiscordGuild
 */
export interface DiscordGuild {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated DiscordGuild.Role roles = 3;
     */
    roles: DiscordGuild_Role[];
    /**
     * @generated from protobuf field: repeated string invite_links = 4;
     */
    inviteLinks: string[];
}
/**
 * @generated from protobuf message DiscordGuild.Role
 */
export interface DiscordGuild_Role {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message EditDiscordMissionRequest
 */
export interface EditDiscordMissionRequest {
    /**
     * @generated from protobuf field: string guild_id = 1;
     */
    guildId: string;
    /**
     * @generated from protobuf field: string role_id = 2;
     */
    roleId: string;
    /**
     * @generated from protobuf field: string invite_link = 3;
     */
    inviteLink: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class NewDiscordMissionRequest$Type extends MessageType<NewDiscordMissionRequest> {
    constructor() {
        super("NewDiscordMissionRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewDiscordMissionRequest>): NewDiscordMissionRequest {
        const message = { projectId: "", finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewDiscordMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewDiscordMissionRequest): NewDiscordMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string final_redirect_url */ 2:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewDiscordMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string final_redirect_url = 2; */
        if (message.finalRedirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewDiscordMissionRequest
 */
export const NewDiscordMissionRequest = new NewDiscordMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewDiscordMissionResponse$Type extends MessageType<NewDiscordMissionResponse> {
    constructor() {
        super("NewDiscordMissionResponse", [
            { no: 1, name: "mission_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewDiscordMissionResponse>): NewDiscordMissionResponse {
        const message = { missionId: "", redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewDiscordMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewDiscordMissionResponse): NewDiscordMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mission_id */ 1:
                    message.missionId = reader.string();
                    break;
                case /* string redirect_url */ 2:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewDiscordMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mission_id = 1; */
        if (message.missionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.missionId);
        /* string redirect_url = 2; */
        if (message.redirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewDiscordMissionResponse
 */
export const NewDiscordMissionResponse = new NewDiscordMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReauthDiscordMissionRequest$Type extends MessageType<ReauthDiscordMissionRequest> {
    constructor() {
        super("ReauthDiscordMissionRequest", [
            { no: 1, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReauthDiscordMissionRequest>): ReauthDiscordMissionRequest {
        const message = { finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReauthDiscordMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReauthDiscordMissionRequest): ReauthDiscordMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string final_redirect_url */ 1:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReauthDiscordMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string final_redirect_url = 1; */
        if (message.finalRedirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReauthDiscordMissionRequest
 */
export const ReauthDiscordMissionRequest = new ReauthDiscordMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReauthDiscordMissionResponse$Type extends MessageType<ReauthDiscordMissionResponse> {
    constructor() {
        super("ReauthDiscordMissionResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReauthDiscordMissionResponse>): ReauthDiscordMissionResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReauthDiscordMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReauthDiscordMissionResponse): ReauthDiscordMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReauthDiscordMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReauthDiscordMissionResponse
 */
export const ReauthDiscordMissionResponse = new ReauthDiscordMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteDiscordMissionRequest$Type extends MessageType<CompleteDiscordMissionRequest> {
    constructor() {
        super("CompleteDiscordMissionRequest", [
            { no: 1, name: "mission_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteDiscordMissionRequest>): CompleteDiscordMissionRequest {
        const message = { missionId: "", finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteDiscordMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteDiscordMissionRequest): CompleteDiscordMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mission_id */ 1:
                    message.missionId = reader.string();
                    break;
                case /* string final_redirect_url */ 2:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteDiscordMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mission_id = 1; */
        if (message.missionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.missionId);
        /* string final_redirect_url = 2; */
        if (message.finalRedirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompleteDiscordMissionRequest
 */
export const CompleteDiscordMissionRequest = new CompleteDiscordMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteDiscordMissionResponse$Type extends MessageType<CompleteDiscordMissionResponse> {
    constructor() {
        super("CompleteDiscordMissionResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteDiscordMissionResponse>): CompleteDiscordMissionResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteDiscordMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteDiscordMissionResponse): CompleteDiscordMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteDiscordMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompleteDiscordMissionResponse
 */
export const CompleteDiscordMissionResponse = new CompleteDiscordMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowDiscordMissionResponse$Type extends MessageType<ShowDiscordMissionResponse> {
    constructor() {
        super("ShowDiscordMissionResponse", [
            { no: 1, name: "guilds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscordGuild },
            { no: 2, name: "guild_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "invite_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ready", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ShowDiscordMissionResponse>): ShowDiscordMissionResponse {
        const message = { guilds: [], guildId: "", roleId: "", inviteLink: "", ready: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowDiscordMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowDiscordMissionResponse): ShowDiscordMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DiscordGuild guilds */ 1:
                    message.guilds.push(DiscordGuild.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string guild_id */ 2:
                    message.guildId = reader.string();
                    break;
                case /* string role_id */ 3:
                    message.roleId = reader.string();
                    break;
                case /* string invite_link */ 4:
                    message.inviteLink = reader.string();
                    break;
                case /* bool ready */ 5:
                    message.ready = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowDiscordMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated DiscordGuild guilds = 1; */
        for (let i = 0; i < message.guilds.length; i++)
            DiscordGuild.internalBinaryWrite(message.guilds[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string guild_id = 2; */
        if (message.guildId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.guildId);
        /* string role_id = 3; */
        if (message.roleId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roleId);
        /* string invite_link = 4; */
        if (message.inviteLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.inviteLink);
        /* bool ready = 5; */
        if (message.ready !== false)
            writer.tag(5, WireType.Varint).bool(message.ready);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowDiscordMissionResponse
 */
export const ShowDiscordMissionResponse = new ShowDiscordMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscordGuild$Type extends MessageType<DiscordGuild> {
    constructor() {
        super("DiscordGuild", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DiscordGuild_Role },
            { no: 4, name: "invite_links", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscordGuild>): DiscordGuild {
        const message = { id: "", name: "", roles: [], inviteLinks: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiscordGuild>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscordGuild): DiscordGuild {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated DiscordGuild.Role roles */ 3:
                    message.roles.push(DiscordGuild_Role.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string invite_links */ 4:
                    message.inviteLinks.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscordGuild, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated DiscordGuild.Role roles = 3; */
        for (let i = 0; i < message.roles.length; i++)
            DiscordGuild_Role.internalBinaryWrite(message.roles[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string invite_links = 4; */
        for (let i = 0; i < message.inviteLinks.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.inviteLinks[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DiscordGuild
 */
export const DiscordGuild = new DiscordGuild$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscordGuild_Role$Type extends MessageType<DiscordGuild_Role> {
    constructor() {
        super("DiscordGuild.Role", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DiscordGuild_Role>): DiscordGuild_Role {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiscordGuild_Role>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscordGuild_Role): DiscordGuild_Role {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscordGuild_Role, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DiscordGuild.Role
 */
export const DiscordGuild_Role = new DiscordGuild_Role$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditDiscordMissionRequest$Type extends MessageType<EditDiscordMissionRequest> {
    constructor() {
        super("EditDiscordMissionRequest", [
            { no: 1, name: "guild_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "invite_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditDiscordMissionRequest>): EditDiscordMissionRequest {
        const message = { guildId: "", roleId: "", inviteLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditDiscordMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditDiscordMissionRequest): EditDiscordMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string guild_id */ 1:
                    message.guildId = reader.string();
                    break;
                case /* string role_id */ 2:
                    message.roleId = reader.string();
                    break;
                case /* string invite_link */ 3:
                    message.inviteLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditDiscordMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string guild_id = 1; */
        if (message.guildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.guildId);
        /* string role_id = 2; */
        if (message.roleId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roleId);
        /* string invite_link = 3; */
        if (message.inviteLink !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.inviteLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditDiscordMissionRequest
 */
export const EditDiscordMissionRequest = new EditDiscordMissionRequest$Type();
