export enum Network {
  Ethereum = 'Ethereum',
  Solana = 'Solana',
}

export enum WidgetState {
  Uninitialized = 'Uninitialized',
  Paused = 'Paused',
  EarlyAccess = 'EarlyAccess',
  PrivateMint = 'PrivateMint',
  PublicMint = 'PublicMint',
}

export enum Themes {
  Pluto = 'Pluto',
  Tea = 'Tea',
  FrogOnALog = 'FrogOnALog',
  KoolAid = 'KoolAid',
  Chrome = 'Chrome',
  Sunshine = 'Sunshine',
}

export enum ProjectPhase {
  EarlyAccess = 'earlyaccess',
  PrivateMint = 'privatemint',
  PublicMint = 'publicmint',
  ProtectedPublicMint = 'protectedpublicmint',
}

export enum PhaseKey {
  inactive = 'inactive',
  active = 'active',
  startingSoon = 'starting_soon',
  ended = 'ended',
}

export const PLUTO_TOKEN_URL =
  'https://storage.googleapis.com/mintkit-images-753c59/2HExL0FCI2Oco4TPjWXgbcCspl5';

export const DEFAULT_WIDGET_WIDTH = 592;
export const TOKEN_IMAGE_WIDTH = 371;
export const TOKEN_PADDING = 40;
export const FULL_TOKEN_WIDTH = TOKEN_IMAGE_WIDTH + TOKEN_PADDING;
export const HEADER_BANNER_HEIGHT = 64;
