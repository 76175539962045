// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "missions.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Mission
 */
export interface Mission {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string key = 3;
     */
    key: string;
    /**
     * @generated from protobuf field: string public_config = 4;
     */
    publicConfig: string; // TODO turn into map<string, string> if we can fifgure out in javascript how to parse and use it
    /**
     * @generated from protobuf field: bool ready = 5;
     */
    ready: boolean;
    /**
     * @generated from protobuf field: string error = 6;
     */
    error: string;
    /**
     * @generated from protobuf field: repeated Mission.Phase phases = 7;
     */
    phases: Mission_Phase[];
}
/**
 * @generated from protobuf message Mission.Phase
 */
export interface Mission_Phase {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message UserMission
 */
export interface UserMission {
    /**
     * @generated from protobuf field: string mission_id = 1;
     */
    missionId: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string key = 3;
     */
    key: string;
    /**
     * @generated from protobuf field: string public_config = 4;
     */
    publicConfig: string; // TODO turn into map<string, string> if we can fifgure out in javascript how to parse and use it
    /**
     * @generated from protobuf field: bool completed = 5;
     */
    completed: boolean;
    /**
     * @generated from protobuf field: string completed_at = 6;
     */
    completedAt: string;
}
/**
 * @generated from protobuf message MissionErrors
 */
export interface MissionErrors {
    /**
     * @generated from protobuf field: optional string id = 1;
     */
    id?: string;
    /**
     * @generated from protobuf field: optional string title = 2;
     */
    title?: string;
}
/**
 * GET /projects/:id/missions
 *
 * @generated from protobuf message IndexMissionResponse
 */
export interface IndexMissionResponse {
    /**
     * @generated from protobuf field: repeated Mission data = 1;
     */
    data: Mission[];
    /**
     * @generated from protobuf field: MissionErrors errors = 2;
     */
    errors?: MissionErrors;
}
/**
 * GET /projects/:id/missions/embed
 *
 * @generated from protobuf message IndexMissionEmbedResponse
 */
export interface IndexMissionEmbedResponse {
    /**
     * @generated from protobuf field: repeated UserMission data = 1;
     */
    data: UserMission[];
}
/**
 * DELETE /missions/:id
 *
 * @generated from protobuf message DeleteMissionResponse
 */
export interface DeleteMissionResponse {
    /**
     * @generated from protobuf field: Mission data = 1;
     */
    data?: Mission;
    /**
     * @generated from protobuf field: MissionErrors errors = 2;
     */
    errors?: MissionErrors;
}
// @generated message type with reflection information, may provide speed optimized methods
class Mission$Type extends MessageType<Mission> {
    constructor() {
        super("Mission", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "public_config", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ready", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mission_Phase }
        ]);
    }
    create(value?: PartialMessage<Mission>): Mission {
        const message = { id: "", projectId: "", key: "", publicConfig: "", ready: false, error: "", phases: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Mission>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Mission): Mission {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string key */ 3:
                    message.key = reader.string();
                    break;
                case /* string public_config */ 4:
                    message.publicConfig = reader.string();
                    break;
                case /* bool ready */ 5:
                    message.ready = reader.bool();
                    break;
                case /* string error */ 6:
                    message.error = reader.string();
                    break;
                case /* repeated Mission.Phase phases */ 7:
                    message.phases.push(Mission_Phase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Mission, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string key = 3; */
        if (message.key !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.key);
        /* string public_config = 4; */
        if (message.publicConfig !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.publicConfig);
        /* bool ready = 5; */
        if (message.ready !== false)
            writer.tag(5, WireType.Varint).bool(message.ready);
        /* string error = 6; */
        if (message.error !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.error);
        /* repeated Mission.Phase phases = 7; */
        for (let i = 0; i < message.phases.length; i++)
            Mission_Phase.internalBinaryWrite(message.phases[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mission
 */
export const Mission = new Mission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mission_Phase$Type extends MessageType<Mission_Phase> {
    constructor() {
        super("Mission.Phase", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Mission_Phase>): Mission_Phase {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Mission_Phase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Mission_Phase): Mission_Phase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Mission_Phase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mission.Phase
 */
export const Mission_Phase = new Mission_Phase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMission$Type extends MessageType<UserMission> {
    constructor() {
        super("UserMission", [
            { no: 1, name: "mission_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "public_config", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "completed_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserMission>): UserMission {
        const message = { missionId: "", projectId: "", key: "", publicConfig: "", completed: false, completedAt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserMission>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMission): UserMission {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mission_id */ 1:
                    message.missionId = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string key */ 3:
                    message.key = reader.string();
                    break;
                case /* string public_config */ 4:
                    message.publicConfig = reader.string();
                    break;
                case /* bool completed */ 5:
                    message.completed = reader.bool();
                    break;
                case /* string completed_at */ 6:
                    message.completedAt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMission, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mission_id = 1; */
        if (message.missionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.missionId);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string key = 3; */
        if (message.key !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.key);
        /* string public_config = 4; */
        if (message.publicConfig !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.publicConfig);
        /* bool completed = 5; */
        if (message.completed !== false)
            writer.tag(5, WireType.Varint).bool(message.completed);
        /* string completed_at = 6; */
        if (message.completedAt !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.completedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserMission
 */
export const UserMission = new UserMission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissionErrors$Type extends MessageType<MissionErrors> {
    constructor() {
        super("MissionErrors", [
            { no: 1, name: "id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MissionErrors>): MissionErrors {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MissionErrors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MissionErrors): MissionErrors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string id */ 1:
                    message.id = reader.string();
                    break;
                case /* optional string title */ 2:
                    message.title = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MissionErrors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string id = 1; */
        if (message.id !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* optional string title = 2; */
        if (message.title !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MissionErrors
 */
export const MissionErrors = new MissionErrors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexMissionResponse$Type extends MessageType<IndexMissionResponse> {
    constructor() {
        super("IndexMissionResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mission },
            { no: 2, name: "errors", kind: "message", T: () => MissionErrors }
        ]);
    }
    create(value?: PartialMessage<IndexMissionResponse>): IndexMissionResponse {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndexMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndexMissionResponse): IndexMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mission data */ 1:
                    message.data.push(Mission.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* MissionErrors errors */ 2:
                    message.errors = MissionErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndexMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mission data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Mission.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* MissionErrors errors = 2; */
        if (message.errors)
            MissionErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IndexMissionResponse
 */
export const IndexMissionResponse = new IndexMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexMissionEmbedResponse$Type extends MessageType<IndexMissionEmbedResponse> {
    constructor() {
        super("IndexMissionEmbedResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserMission }
        ]);
    }
    create(value?: PartialMessage<IndexMissionEmbedResponse>): IndexMissionEmbedResponse {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndexMissionEmbedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndexMissionEmbedResponse): IndexMissionEmbedResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated UserMission data */ 1:
                    message.data.push(UserMission.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndexMissionEmbedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated UserMission data = 1; */
        for (let i = 0; i < message.data.length; i++)
            UserMission.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IndexMissionEmbedResponse
 */
export const IndexMissionEmbedResponse = new IndexMissionEmbedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMissionResponse$Type extends MessageType<DeleteMissionResponse> {
    constructor() {
        super("DeleteMissionResponse", [
            { no: 1, name: "data", kind: "message", T: () => Mission },
            { no: 2, name: "errors", kind: "message", T: () => MissionErrors }
        ]);
    }
    create(value?: PartialMessage<DeleteMissionResponse>): DeleteMissionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMissionResponse): DeleteMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mission data */ 1:
                    message.data = Mission.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* MissionErrors errors */ 2:
                    message.errors = MissionErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mission data = 1; */
        if (message.data)
            Mission.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* MissionErrors errors = 2; */
        if (message.errors)
            MissionErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteMissionResponse
 */
export const DeleteMissionResponse = new DeleteMissionResponse$Type();
