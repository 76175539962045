import { css } from '@emotion/react';
import Countdown from 'react-countdown';
import { Colors } from '../../themes/colors';

type CountdownTimerProps = {
  onComplete?: () => void;
  targetTime: number | Date;
};

const ONE_DAY = 1000 * 60 * 60 * 24;
const padTime = (time: number) => (time < 10 ? `0${time}` : time);

const CountdownTimer = (props: CountdownTimerProps) => {
  const targetTime =
    typeof props.targetTime === 'number'
      ? new Date(props.targetTime)
      : props.targetTime;

  const renderNumberTextPair = (num: number, text: string) => {
    const pluralSuffix = num !== 1 ? 's' : '';
    const zeroSuffix = num < 10 ? '0' : '';

    return (
      <div
        className="flex self-center flex-col justify-center items-center text-center"
        css={css`
          width: 80px;
          color: ${Colors.DARK_GRAY};
        `}
      >
        <span
          className="text-7xl text-center font-semibold"
          css={css`
            height: 80px;
            line-height: 80px;
          `}
        >
          {zeroSuffix}
          {num}
        </span>
        <span
          css={css`
            height: 40px;
            font-weight: 510;
            line-height: 40px;
            text-align: center;
          `}
        >
          {text}
          {pluralSuffix}
        </span>
      </div>
    );
  };

  return (
    <Countdown
      date={targetTime}
      onComplete={props.onComplete}
      daysInHours={targetTime.getTime() - Date.now() < ONE_DAY}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        return (
          <div
            className="flex justify-between p-6 rounded-2xl"
            css={css`
              background: ${Colors.LIGHT_GRAY};
            `}
          >
            {renderNumberTextPair(days, 'day')}
            {renderNumberTextPair(hours, 'hour')}
            {renderNumberTextPair(minutes, 'minute')}
            {renderNumberTextPair(seconds, 'second')}
          </div>
        );
      }}
    />
  );
};

export default CountdownTimer;
