import { useStore } from '../../store/store';
import { PhaseKey } from '../../constants/shared';
import CountdownTimer from '../mintkitAtoms/CountdownTimer';

type MintComingSoonProps = {};

const MintComingSoon = (props: MintComingSoonProps) => {
  const startsAt = useStore((s) => s.projectSlice.appState.phase?.startsAt);
  const updateProjectPhaseKey = useStore(
    (s) => s.projectSlice.updateProjectPhaseKey
  );
  const launchTime = new Date(startsAt);

  const markCountdownComplete = () => updateProjectPhaseKey(PhaseKey.active);

  return (
    <CountdownTimer
      targetTime={launchTime}
      onComplete={markCountdownComplete}
    />
  );
};

export default MintComingSoon;
