import { Theme } from '../types/shared';
import { Colors } from './colors';

const Tea: Theme = {
  primary: {
    background: Colors.TEA,
    text: Colors.DARK_GRAY,
  },
  secondary: {
    background: Colors.CREAMY_MOCHA,
    text: Colors.DARK_GRAY,
  },
  canvas: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionButton: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionBody: {
    background: Colors.LIGHT_GRAY,
    text: Colors.DARK_GRAY,
  },
};
export default Tea;
