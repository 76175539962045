import { css } from '@emotion/react';
import { PhaseKey, ProjectPhase } from '../../constants/shared';
import { useStore } from '../../store/store';
import { Colors } from '../../themes/colors';

type PhaseBadgeProps = {};

const PhaseBadge = (props: PhaseBadgeProps) => {
  const phase = useStore(
    (s) => s.projectSlice.appState.phase?.kind
  ) as ProjectPhase;
  const phaseKey = useStore((s) => s.projectSlice.appState.phaseKey);

  let label = 'Minting Soon';
  if (![ProjectPhase.EarlyAccess, ProjectPhase.PrivateMint].includes(phase)) {
    if (phaseKey === PhaseKey.active) label = 'Minting Live';
    else if (phaseKey === PhaseKey.ended) label = 'Minting Ended';
  } else if (phase === ProjectPhase.PrivateMint) {
    if (phaseKey === PhaseKey.startingSoon) {
      label = 'VIP Minting Soon';
    } else if (phaseKey === PhaseKey.active) {
      label = 'VIP Minting Live';
    } else if (phaseKey === PhaseKey.ended) {
      label = 'VIP Minting Ended';
    }
  }

  if (phaseKey === PhaseKey.inactive) {
    label = 'Inactive';
  }

  return (
    <div
      css={css`
        background: ${Colors.BLACK};
        color: ${Colors.WHITE};
        line-height: 20px;
        min-width: 120px;
        font-weight: 590;
        text-align: center;
      `}
      className="p-2 rounded-lg select-none"
    >
      {label}
    </div>
  );
};

export default PhaseBadge;
