import { merge } from 'lodash';
import * as MissionsProto from '../async/proto/missions/missions';
import * as ProjectsProto from '../async/proto/projects/projects';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { StoreApi } from 'zustand';
import { Store } from './store';

export interface Mission {
  id: Missions;
  internalID?: string;
  order: number;
  isComplete: boolean;
  publicConfig?: any; // TODO I know I can do better
}

export type MissionUpdate = Partial<Record<Missions, Partial<Mission>>>;
export interface MissionSlice {
  areMissionsLoaded: boolean;
  currentlySelectedMission?: Missions;
  enabledMissions: Missions[];
  missions: Record<Missions, Mission>;
  nextMission?: Missions;
  completeMission: (mission: Missions, alertServer?: boolean) => void;
  fetchMissions: (reinitializeMissionState?: boolean) => Promise<void>;
  gotoNextUncompletedMission: () => void;
  setCurrentlySelectedMission: (mission: Missions) => void;
  updateMissions: (updatedMissions: MissionUpdate) => void;
}

export enum Missions {
  discord = 'discord',
  TwitterMission = 'twitter',
  EthMission = 'eth_balance',
  NFTMission = 'NFTMission',
  WalletMission = 'WalletMission',
  RegistrationMission = 'RegistrationMission',
}

const initialMissions = {
  [Missions.WalletMission]: {
    id: Missions.WalletMission,
    order: 0,
    isComplete: false,
  },
  [Missions.EthMission]: {
    id: Missions.EthMission,
    order: 1,
    isComplete: false,
  },
  [Missions.NFTMission]: {
    id: Missions.NFTMission,
    order: 2,
    isComplete: false,
  },
  [Missions.TwitterMission]: {
    id: Missions.TwitterMission,
    order: 3,
    isComplete: false,
  },
  [Missions.discord]: {
    id: Missions.discord,
    order: 4,
    isComplete: false,
  },
  [Missions.RegistrationMission]: {
    id: Missions.RegistrationMission,
    order: 5,
    isComplete: false,
  },
};

export const missionSlice: MissionSlice = lens(
  (setState, getState, api: StoreApi<Store>) => {
    return {
      currentlySelectedMission: undefined,
      enabledMissions: [Missions.WalletMission],
      missions: initialMissions,
      areMissionsLoaded: false,
      setCurrentlySelectedMission: (currentlySelectedMission?: Missions) =>
        setState({ currentlySelectedMission }),
      fetchMissions: async (reinitializeMissionState?: boolean) => {
        setState({ areMissionsLoaded: false });

        const projectID = api.getState().projectSlice.projectID;
        if (!projectID) throw new Error('No Project ID is set');

        const response = await axios.get(
          `/projects/${projectID}/missions/embed`
        );

        const missionData = MissionsProto.IndexMissionEmbedResponse.fromJson(
          response.data
        ).data;

        if (reinitializeMissionState) {
          getState().updateMissions({ ...initialMissions });
          getState().setCurrentlySelectedMission(undefined);
        }

        const swizzledMissions: MissionUpdate = {};

        missionData.forEach((mission) => {
          swizzledMissions[mission.key] = {
            isComplete:
              getState().missions[mission.key].isComplete || mission.completed,
            internalID: mission.missionId,
            publicConfig: JSON.parse(mission.publicConfig),
          };
        });

        setState({
          areMissionsLoaded: true,
          enabledMissions: [
            Missions.WalletMission,
            ...missionData
              .sort(
                (a, b) =>
                  getState().missions[a.key as Missions].order -
                  getState().missions[b.key as Missions].order
              )
              .map((m) => m.key as Missions),
            Missions.RegistrationMission,
          ],
        });

        getState().updateMissions(swizzledMissions);
      },
      completeMission: async (mission: Missions, alertServer?: boolean) => {
        if (!alertServer)
          return getState().updateMissions({ [mission]: { isComplete: true } });

        const { missionSlice } = api.getState();
        const missionId = missionSlice.missions.eth_balance.internalID;
        try {
          await axios.post(`/missions/${mission}/${missionId}/complete`);
          getState().updateMissions({ [mission]: { isComplete: true } });
        } catch (e) {
          console.error('Handle this', e);
        }
      },
      gotoNextUncompletedMission: () => {
        const enabledMissions = Object.values(getState().missions).filter((m) =>
          getState().enabledMissions.includes(m.id)
        );
        const currentMission: Mission =
          getState().missions[getState().currentlySelectedMission] ||
          getState().missions[Missions.WalletMission];

        const uncompletedMissions = enabledMissions.filter(
          (m) => m.order >= currentMission.order && !m.isComplete
        );
        const nextMission = uncompletedMissions?.[0]?.id;
        setState({
          currentlySelectedMission: nextMission || Missions.RegistrationMission,
          nextMission,
        });
      },
      updateMissions: (updatedMissions: MissionUpdate) => {
        setState({ missions: merge({}, getState().missions, updatedMissions) });
        getState().gotoNextUncompletedMission();
      },
    };
  }
);
