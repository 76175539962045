import { TwitterErrors, OauthErrors, DiscordErrors } from './enums';
import { Missions } from './store/missionSlice';
import { useStore } from './store/store';

type Message = {
  message: string;
  placeholders?: Record<string, (args?: any) => string>;
};

const messages: Record<string, Message> = {
  [OauthErrors.FAILED_OAUTH]: {
    message: 'Authorization failed',
  },
  [OauthErrors.FAILED_IDENTITY]: {
    message: 'Authorization failed',
  },
  [OauthErrors.INTERNAL_SERVER_ERROR]: {
    message:
      'An unknown error has occurred, please refresh the page and try again.',
  },
  [TwitterErrors.TWITTER_NOT_FOLLOWING]: {
    message: 'Please follow @{twitterHandle} on twitter and verify again',
    placeholders: {
      twitterHandle: () =>
        useStore.getState().missionSlice.missions[Missions.TwitterMission]
          .publicConfig?.username,
    },
  },
  [TwitterErrors.TWITTER_TOO_MANY_REQUESTS]: {
    message: 'Too many requests. Please try verifying again in a bit',
  },
  [DiscordErrors.DISCORD_GUILD_ID_MISSING]: {
    message: 'Discord server missing Guild ID. Please contact administrator',
  },
  [DiscordErrors.DISCORD_ROLE_ID_MISSING]: {
    message: 'Discord server missing Role ID. Please contact administrator',
  },
  [DiscordErrors.DISCORD_FAILED_TO_ASSIGN_ROLE]: {
    message: 'Please join {guildName} on discord and verify again',
    placeholders: {
      guildName: () =>
        useStore.getState().missionSlice.missions[Missions.discord].publicConfig
          ?.guild_name,
    },
  },
};

const formatMessage = (messageID: string, placeholderArgs?: any) => {
  if (!messages[messageID]) {
    throw new Error(
      `Attempted to display message with unknown key ${messageID}`
    );
  }

  const { message, placeholders } = messages[messageID];
  if (!placeholders) return message;

  let parsedMessage = message;
  for (let key in placeholders) {
    parsedMessage = parsedMessage.replaceAll(
      `{${key}}`,
      placeholders[key](placeholderArgs)
    );
  }

  return parsedMessage;
};

export default formatMessage;
