import { Missions } from '../../../../../store/missionSlice';
import { useStore } from '../../../../../store/store';
import DisclosureBody from './Disclosure/DisclosureBody';
import DisclosureButton from './Disclosure/DisclosureButton';
import TwitterInvitation from './TwitterInvitation';

export type TwitterPublicData =
  | {
      username: string;
    }
  | undefined;

const TwitterMission = () => {
  const mission = useStore(
    (s) => s.missionSlice.missions[Missions.TwitterMission]
  );
  const connectTwitter = useStore((s) => s.twitterSlice.connectTwitter);
  const publicConfig = mission.publicConfig as TwitterPublicData;
  const loadError = useStore((s) => s.notificationSlice.loadError);
  const errorMessage =
    loadError?.type === Missions.TwitterMission ? loadError.message : null;

  return (
    <>
      <DisclosureButton
        mission={Missions.TwitterMission}
        name="Sign in with Twitter"
      />
      <DisclosureBody
        mission={Missions.TwitterMission}
        description={`Verify you are following @${publicConfig?.username} to complete this step.`}
        errorMessage={errorMessage}
        actionButton={{
          onClick: connectTwitter,
          label: 'Sign in with Twitter',
        }}
      />
    </>
  );
};

export default TwitterMission;
