import { css } from '@emotion/react';
import { Disclosure } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { Missions } from '../../../../../../store/missionSlice';
import { useStore } from '../../../../../../store/store';
import ActionButton, { ActionButtonDef } from './ActionButton';
import AnimateHeight from 'react-animate-height';
import { Colors } from '../../../../../../themes/colors';

type DisclosureBodyProps = {
  mission: Missions;
  description: string;
  errorMessage?: string;
  actionButton?: ActionButtonDef;
};

const DisclosureBody = (props: DisclosureBodyProps) => {
  const isWalletConnected = useStore((s) => !!s.userSlice.walletAddress);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const mission = useStore((s) => s.missionSlice.missions[props.mission]);
  const isCurrentMissionSelected = useStore(
    (s) => s.missionSlice.currentlySelectedMission === mission.id
  );
  const theme = useStore((s) => s.themeSlice.theme);
  const isAuthRequired = useStore(
    (s) => s.projectSlice.appState.isAuthRequired
  );

  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (!isCurrentMissionSelected) {
      setHeight(0);
    } else {
      setHeight('auto');
    }
  }, [isCurrentMissionSelected]);

  let description = props.description;
  if (mission.isComplete) description = 'Step Completed';
  if (!isWalletConnected) description = 'Connect your wallet to continue';
  else if (isAuthRequired && !isUserSignedIn)
    description = 'Sign in to continue';
  return (
    <AnimateHeight
      id="example-panel"
      duration={150}
      height={height} // see props documentation below
    >
      <Disclosure.Panel
        static
        className={`px-4 py-6 flex flex-col`}
        css={css`
          background: ${theme.missionBody.background};
          color: ${theme.missionBody.text};
        `}
      >
        <div className="flex justify-between items-center">
          <span
            css={css`
              flex: 0 1 65%;
            `}
            className="flex items-center mr-4"
          >
            {description}
          </span>
          <ActionButton definition={props.actionButton} mission={mission} />
        </div>
        {props.errorMessage &&
          !mission.isComplete &&
          isWalletConnected &&
          isUserSignedIn && (
            <span
              className="mt-4"
              css={css`
                color: ${Colors.RED_STATUS};
              `}
            >
              {props.errorMessage}
            </span>
          )}
      </Disclosure.Panel>
    </AnimateHeight>
  );
};

export default DisclosureBody;
