import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import {
  normalizeWalletAddress,
  requireProjectID,
  requireWalletAddress,
  Store,
  trimAddress,
} from './store';
import * as ProjectsProto from '../async/proto/projects/projects';
import { BigNumber } from 'ethers';
import { ProjectPhase } from '../constants/shared';
import { MintStatusType } from './projectSlice';

export interface MintSlice {
  mintIsLiveTimestamp?: number;
  tokenCurrentSupply: number;
  tokenMaxSupply: number;
  amountOfTokensSelected: number;
  updateAmountOfTokensSelected: (amountOfTokensSelected: number) => void;
  fetchTokenTotalSupply: () => void;
  fetchTokenCurrentSupply: () => void;
  fetchContractDetails: () => void;
  fetchIsContractPaused: () => void;
  fetchWalletLimit: () => void;
  fetchAllContractDetails: () => Promise<void>;
  walletLimit: number;
  contractDetails?: ProjectsProto.SignedTokenResponse;
  isContractPaused: boolean;
  mint: () => void;
}

export const mintSlice: MintSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    tokenCurrentSupply: 0,
    tokenMaxSupply: 0,
    amountOfTokensSelected: 1,
    mintIsLiveTimestamp: undefined,
    isContractPaused: false,
    walletLimit: 0,
    fetchTokenTotalSupply: async () => {
      const projectID = requireProjectID();
      const response = await axios.get(
        `/projects/${projectID}/contract?function=totalSupply&call=true`
      );
      const { jsonOutputs } = ProjectsProto.ContractCallResponse.fromJson(
        response.data
      );
      const parsedValue = JSON.parse(jsonOutputs)[0];
      setState({
        tokenMaxSupply: Number.parseInt(parsedValue.value || '0'),
      });
    },
    fetchTokenCurrentSupply: async () => {
      const projectID = requireProjectID();
      const response = await axios.get(
        `/projects/${projectID}/contract?function=supply&call=true`
      );
      const { jsonOutputs } = ProjectsProto.ContractCallResponse.fromJson(
        response.data
      );

      const parsedValue = JSON.parse(jsonOutputs)[0];
      setState({
        tokenCurrentSupply: Number.parseInt(parsedValue.value || '0'),
      });
    },
    fetchIsContractPaused: async () => {
      const projectID = requireProjectID();
      const response = await axios.get(
        `/projects/${projectID}/contract?function=paused&call=true`
      );
      const { jsonOutputs } = ProjectsProto.ContractCallResponse.fromJson(
        response.data
      );

      const parsedValue = JSON.parse(jsonOutputs)[0];
      setState({
        isContractPaused: parsedValue.value,
      });
    },
    fetchWalletLimit: async () => {
      const projectID = requireProjectID();
      const walletAddress = requireWalletAddress();

      const response = await axios.get(
        `/projects/${projectID}/contract?function=walletLimit&call=true&user_address=${normalizeWalletAddress(
          walletAddress
        )}`
      );
      const { jsonOutputs } = ProjectsProto.ContractCallResponse.fromJson(
        response.data
      );

      const parsedValue = JSON.parse(jsonOutputs)[0];
      setState({
        walletLimit: Number.parseInt(parsedValue.value || '0'),
      });
    },

    fetchContractDetails: async () => {
      const projectID = requireProjectID();

      const amountOfTokensSelected = getState().amountOfTokensSelected;
      const phase = api.getState().projectSlice.appState.phase?.kind;

      let endpoint = `/projects/${projectID}/`;
      if (phase === ProjectPhase.PublicMint) {
        endpoint += `contract?function=mint`;
      } else {
        const walletAddress = requireWalletAddress();
        endpoint += `signed_token?function=mint&quantity=${amountOfTokensSelected}&user_address=${normalizeWalletAddress(
          walletAddress
        )}`;
      }

      const response = await axios.get(endpoint);
      const contractDetails = ProjectsProto.SignedTokenResponse.fromJson(
        response.data
      );
      setState({ contractDetails });
    },
    fetchAllContractDetails: async () => {
      await Promise.allSettled([
        getState().fetchContractDetails(),
        getState().fetchIsContractPaused(),
        getState().fetchTokenCurrentSupply(),
        getState().fetchTokenTotalSupply(),
        getState().fetchWalletLimit(),
      ]);
    },
    updateAmountOfTokensSelected: (amountOfTokensSelected: number) =>
      setState({ amountOfTokensSelected }),
    mint: async () => {
      requireWalletAddress();

      if (
        api.getState().projectSlice.appState.isAuthRequired &&
        !getState().contractDetails
      ) {
        throw new Error('Attempted to mint without required information');
      }
      const amount = getState().amountOfTokensSelected;
      const tokenCurrentSupply = getState().tokenCurrentSupply;
      const tokenTotalSupply = getState().tokenMaxSupply;
      const sdk = api.getState().universalSlice.sdk;
      const phase = api.getState().projectSlice.appState?.phase.kind;
      if (amount > tokenCurrentSupply) {
        throw new Error(
          `Attempting to mint more tokens (${amount} selected. ${tokenCurrentSupply} available.) then are available`
        );
      }
      if (amount > tokenTotalSupply) {
        throw new Error(
          `Attempting to mint more tokens (${amount} selected. ${tokenTotalSupply} max exist.) than max supply`
        );
      }
      if (amount <= 0) {
        throw new Error(
          `Attempting to mint an invalid amount (${amount}) of tokens`
        );
      }

      const inputs = getState().contractDetails?.jsonInputs;
      const parsedInput = inputs
        ? JSON.parse(getState().contractDetails?.jsonInputs)
        : [];

      let parsedArguments = [];
      /**
       * In a public mint, we need to add in the function args ourselves. In other mints
       * the API does it for us.
       */
      if (phase === ProjectPhase.PublicMint) {
        parsedInput.forEach((input) => {
          switch (input.name) {
            case 'quantity': {
              parsedArguments.push(amount);
              break;
            }
            default: {
              throw new Error(
                'Encountered unknown input when attempted to mint'
              );
            }
          }
        });
      } else {
        parsedArguments = parsedInput.map((i) =>
          i.type === 'uint256' ? BigNumber.from(i.value) : i.value
        );
      }

      sdk.writeContract({
        addressOrName: trimAddress(getState().contractDetails?.contractAddress),
        contractInterface: getState().contractDetails?.abi,
        functionName: getState().contractDetails?.function,
        args: parsedArguments,
      });
    },
  })
);
