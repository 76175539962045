import { StoreApi } from 'zustand';
import axios from '../../async/axios';
import * as DiscordProto from '../../async/proto/missions/discord/discord';
import { lens } from '@dhmk/zustand-lens';
import { Store } from '../store';
export interface DiscordSlice {
  connectDiscord: () => Promise<void>;
}

export const discordSlice: DiscordSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    connectDiscord: async () => {
      const { missionSlice, universalSlice } = api.getState();

      const missionId = missionSlice.missions.discord.internalID;
      const finalRedirectUrl = universalSlice.hostOrigin;
      const sdk = universalSlice.sdk;
      const { data } = await axios.post(`/missions/discord/complete`, {
        missionId,
        finalRedirectUrl,
      } as DiscordProto.CompleteDiscordMissionRequest);
      const { redirectUrl } =
        DiscordProto.CompleteDiscordMissionResponse.fromJson(data);

      if (redirectUrl) {
        sdk.redirect(redirectUrl);
      }
    },
  })
);
