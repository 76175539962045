import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import WalletRainbow from '../../assets/images/walletRainbow.svg';
import { Network, ProjectPhase } from '../../constants/shared';
import { useStore } from '../../store/store';
import { Colors } from '../../themes/colors';
import { shortenWalletAddress } from '../../utils/eth';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';

const WalletBadge = () => {
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  const theme = useStore((s) => s.themeSlice.theme);
  const sdk = useStore((s) => s.universalSlice.sdk);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const signOut = useStore((s) => s.authSlice.signOut);
  const isAuthRequired = useStore(
    (s) => s.projectSlice.appState.isAuthRequired
  );
  const [userClickedConnect, setUserClickedConnect] = useState(false);
  const isFullySignedIn = walletAddress && (!isAuthRequired || isUserSignedIn);
  const projectID = useStore((s) => s.projectSlice.projectID);

  const signIn = (walletAddress: string) => {
    if (!isAuthRequired) return;

    sdk.signIn({
      walletAddress,
      network: process.env.NETWORK_ID as Network,
      chainID: 1,
    });
  };

  useEffect(() => {
    // We don't want the scenario where the user loads the page,
    // and we immediately pop a message on them. We want them to
    // first take an action to trigger the popup
    if (walletAddress && userClickedConnect) {
      setUserClickedConnect(false);

      if (isFullySignedIn) return;
      signIn(walletAddress);
    }
  }, [userClickedConnect, walletAddress]);

  if (!projectID) return;

  let buttonConf: any = {};
  if (isFullySignedIn) {
    buttonConf.color = theme.primary.background;
    buttonConf.content = (
      <>
        <img
          className="rounded-full h-8 w-8 mr-2 select-none"
          src={WalletRainbow}
        />
        <span className="leading-4 mr-1 select-none">
          {shortenWalletAddress(walletAddress)}
        </span>
        <CloseButton
          onClick={async () => {
            if (walletAddress) sdk.disconnectWallet();
            // Calling disconnectWallet triggers a signout, so only do it
            // if we aren't already disconnecting the wallet.
            else signOut();
          }}
        />
      </>
    );
    buttonConf.css = css`
      color: ${theme.primary.text};
      width: 200px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      font-weight: 590;
    `;
    buttonConf.className =
      'flex items-center justify-between pl-1 pr-4 rounded-full flex-initial';
  } else {
    buttonConf.className = 'flex flex-initial';
    buttonConf.onClick = () => {
      setUserClickedConnect(true);
      if (!walletAddress) sdk.connectWallet();
    };
    buttonConf.color = Colors.BLACK;
    buttonConf.css = css`
      width: ${walletAddress ? '90px' : '145px'};
    `;
    buttonConf.content = walletAddress ? 'Sign In' : 'Connect Wallet';
  }

  return (
    <Button
      className={buttonConf.className}
      onClick={buttonConf.onClick}
      color={buttonConf.color}
      css={buttonConf.css}
      transitionColor={buttonConf.transitionColor}
    >
      {buttonConf.content}
    </Button>
  );
};

export default WalletBadge;
