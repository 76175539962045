import { css } from '@emotion/react';
import { Network } from '../../../../../../constants/shared';
import { Mission, Missions } from '../../../../../../store/missionSlice';
import { useStore } from '../../../../../../store/store';
import { Colors } from '../../../../../../themes/colors';
import Button from '../../../../../atoms/Button';
import ConnectButton from '../../../../../mintkitAtoms/ConnectButton';

type ActionButtonProps = {
  mission: Mission;
  definition?: ActionButtonDef;
};

export type ActionButtonDef = {
  isDisabled?: boolean;
  onClick: () => void;
  label: string;
};

const ActionButton = (props: ActionButtonProps) => {
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const areAllMissionsCompleted = useStore(
    (s) => s.missionSlice.missions.RegistrationMission.isComplete
  );
  const gotoNextUncompletedMission = useStore(
    (s) => s.missionSlice.gotoNextUncompletedMission
  );
  const sdk = useStore((s) => s.universalSlice.sdk);
  const isProjectLoaded = !!useStore((s) => s.projectSlice.isProjectLoaded);
  const areMissionsLoaded = useStore((s) => s.missionSlice.areMissionsLoaded);
  const isAuthRequired = useStore(
    (s) => s.projectSlice.appState.isAuthRequired
  );

  let definition = props.definition;

  let color = Colors.BLACK;
  switch (props.mission.id) {
    case Missions.TwitterMission:
      color = Colors.TWITTER_BLUE;
      break;
    case Missions.discord:
      color = Colors.DISCORD_PURPLE;
      break;
  }

  if (!walletAddress) {
    definition = {
      isDisabled: false,
      onClick: () => sdk.connectWallet(),
      label: 'Connect Wallet',
    };
  } else if (!isUserSignedIn) {
    definition = {
      isDisabled: false,
      onClick: () =>
        sdk.signIn({
          walletAddress,
          network: process.env.NETWORK_ID as Network,
          chainID: 1,
        }),
      label: 'Sign In',
    };
  } else if (props.mission.isComplete && !areAllMissionsCompleted) {
    definition = {
      isDisabled: false,
      onClick: () => gotoNextUncompletedMission(),
      label: 'Next Step',
    };
  }

  if (
    !definition ||
    !walletAddress ||
    (isAuthRequired && !isUserSignedIn) ||
    (props.mission.isComplete && !!walletAddress && isUserSignedIn)
  ) {
    return null;
  }

  const isButtonDisabled =
    definition?.isDisabled ||
    !isProjectLoaded ||
    (!areMissionsLoaded && !!walletAddress && isUserSignedIn);

  if (!walletAddress || !isUserSignedIn) {
    return <ConnectButton />;
  }

  return (
    <Button
      onClick={definition.onClick}
      disabled={isButtonDisabled}
      color={color}
    >
      {definition.label}
    </Button>
  );
};

export default ActionButton;
