import { css } from '@emotion/react';
import {
  HEADER_BANNER_HEIGHT,
  PLUTO_TOKEN_URL,
  ProjectPhase,
} from '../../constants/shared';
import { useStore } from '../../store/store';
import { applyMediaWidth } from '../../store/universalSlice';
import { PresentationMode, WIDTH_BREAKPOINT_TYPES } from '../../types/shared';

type MainTokenImageProps = {
  width: number;
};

const MainTokenImage = (props: MainTokenImageProps) => {
  const adminUploadedImage = useStore((s) => s.projectSlice.imageUrl);
  const mode = useStore((s) => s.projectSlice.appState.presentationMode);
  const isHorizontal = mode === PresentationMode.HORIZONTAL_IMAGE;
  const phase = useStore((s) => s.projectSlice.appState.phase);
  const hasUserCompletedPhase = useStore(
    (s) => s.projectSlice.appState.hasUserCompletedPhase
  );

  if (
    mode === PresentationMode.NO_IMAGE ||
    phase.kind === ProjectPhase.EarlyAccess ||
    hasUserCompletedPhase ||
    applyMediaWidth(WIDTH_BREAKPOINT_TYPES.LG) ||
    // TODO vertical mode
    !isHorizontal
  )
    return null;

  return (
    <img
      src={adminUploadedImage || PLUTO_TOKEN_URL}
      className="object-contain"
      css={css`
        height: 100%;
        width: ${props.width + HEADER_BANNER_HEIGHT + 1}px;
        max-width: ${props.width + HEADER_BANNER_HEIGHT + 1}px;
        z-index: 2;
      `}
    />
  );
};

export default MainTokenImage;
