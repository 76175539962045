import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { useStore } from '../../store/store';
import { IconMinus, IconPlus } from '@tabler/icons';
import Button from '../atoms/Button';
import { Colors } from '../../themes/colors';

type AmountSelectorProps = {
  maxAmount: number;
};

const AmountSelector = (props: AmountSelectorProps) => {
  const tokenCurrentSupply = useStore((s) => s.mintSlice.tokenCurrentSupply);
  const updateAmountOfTokensSelected = useStore(
    (s) => s.mintSlice.updateAmountOfTokensSelected
  );
  const amountOfTokensSelected = useStore(
    (s) => s.mintSlice.amountOfTokensSelected
  );
  const walletLimit = useStore((s) => s.mintSlice.walletLimit);
  const amountRef = useRef(amountOfTokensSelected);
  const maxAmountOfTokens = useRef(0);
  const theme = useStore((s) => s.themeSlice.theme);

  /**
   * You have to do this because we are accessing state within a callback,
   * so it can get stale. Instead we store the value in a ref
   * and update that ref on every render
   */
  useEffect(() => {
    amountRef.current = amountOfTokensSelected;
    maxAmountOfTokens.current = Math.min(
      tokenCurrentSupply,
      props.maxAmount,
      walletLimit
    );
  });

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowUp':
          updateAmount(amountRef.current + 1);
          break;
        case 'ArrowLeft':
        case 'ArrowDown':
          updateAmount(amountRef.current - 1);
          break;
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => document.removeEventListener('keydown', handleKeydown);
  }, []);

  const updateAmount = (amount: number) => {
    if (amount > maxAmountOfTokens.current || amount < 1) return;
    updateAmountOfTokensSelected(amount);
  };

  const renderCircle = (isMinus: boolean) => {
    const isDisabled =
      (amountOfTokensSelected >=
        Math.min(tokenCurrentSupply, props.maxAmount, walletLimit) &&
        !isMinus) ||
      (amountOfTokensSelected <= 1 && isMinus);

    return (
      <Button
        className="w-16 h-16"
        disabled={isDisabled}
        color={theme.primary.background as Colors}
        textColor={theme.primary.text as Colors}
        onClick={() =>
          updateAmount(
            isMinus ? amountOfTokensSelected - 1 : amountOfTokensSelected + 1
          )
        }
      >
        {isMinus ? <IconMinus /> : <IconPlus />}
      </Button>
    );
  };

  return (
    <div
      className="flex justify-between p-6 rounded-2xl items-center"
      css={css`
        background: ${Colors.LIGHT_GRAY};
      `}
    >
      {renderCircle(true)}
      <div
        className="text-8xl font-bold text-center select-none"
        css={css`
          line-height: 80px;
        `}
      >
        {amountOfTokensSelected}
      </div>
      {renderCircle(false)}
    </div>
  );
};

AmountSelector.defaultProps = {
  maxAmount: 5,
};

export default AmountSelector;
