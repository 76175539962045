import { lens } from '@dhmk/zustand-lens';
import { StoreApi } from 'zustand';
import { Store } from './store';

type UserData = {
  userID?: string;
  projectID?: string;
  projectName?: string;
};

export interface UserSlice {
  isUserSignedIn: boolean;
  walletAddress?: string;
  chainID?: number;
  updateWalletAddress: (walletAddress: string) => void;
  updateChainID: (chainID: number) => void;
}

export const userSlice: UserSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    isUserSignedIn: false,
    walletAddress: undefined,
    chainID: undefined,
    updateWalletAddress: (walletAddress?: string) =>
      setState({ walletAddress }),
    updateChainID: (chainID?: number) => setState({ chainID }),
  })
);
