import { useEffect } from 'react';
import { Missions } from '../../../../../store/missionSlice';
import { useStore } from '../../../../../store/store';
import DisclosureBody from './Disclosure/DisclosureBody';
import DisclosureButton from './Disclosure/DisclosureButton';

const VerifyWalletOwnershipMission = () => {
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  const isMissionComplete = useStore(
    (s) => s.missionSlice.missions[Missions.WalletMission].isComplete
  );
  const gotoNextUncompletedMission = useStore(
    (s) => s.missionSlice.gotoNextUncompletedMission
  );
  const completeMission = useStore((s) => s.missionSlice.completeMission);

  useEffect(() => {
    const checkStatus = async () => {
      if (isUserSignedIn && walletAddress && !isMissionComplete) {
        completeMission(Missions.WalletMission);
        gotoNextUncompletedMission();
      }
    };
    checkStatus();
  }, [isUserSignedIn, walletAddress, isMissionComplete]);

  return (
    <>
      <DisclosureButton
        mission={Missions.WalletMission}
        name={'Verify Wallet Ownership'}
      />
      <DisclosureBody
        mission={Missions.WalletMission}
        description={
          isUserSignedIn
            ? 'Wallet verified'
            : `Connect your wallet and sign a message to verify your account.`
        }
      />
    </>
  );
};

export default VerifyWalletOwnershipMission;
