import { useStore } from '../../../../store/store';
import { Missions } from '../../../../store/missionSlice';
import DiscordMission from './Missions/DiscordMission';
import EthBalanceMission from './Missions/EthBalanceMission';
import RegistrationCompletedMission from './Missions/RegistrationCompletedMission';
import TwitterMission from './Missions/TwitterMission';
import VerifyWalletOwnershipMission from './Missions/VerifyWalletOwnershipMission';
import { Disclosure } from '@headlessui/react';
import { css } from '@emotion/react';
import { rgba } from 'emotion-rgba';

const EarlyAccessActive = () => {
  const enabledMissions = useStore((s) => s.missionSlice.enabledMissions);
  const theme = useStore((s) => s.themeSlice.theme);

  return (
    <div
      className="relative flex flex-col align-center overflow-hidden rounded-2xl"
      css={css`
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
      `}
    >
      {enabledMissions.map((mission, index) => {
        let missionComponent = null;
        switch (mission) {
          case Missions.WalletMission: {
            missionComponent = <VerifyWalletOwnershipMission key={mission} />;
            break;
          }
          case Missions.discord: {
            missionComponent = <DiscordMission key={mission} />;
            break;
          }
          case Missions.EthMission: {
            missionComponent = <EthBalanceMission key={mission} />;
            break;
          }
          case Missions.NFTMission: {
            missionComponent = null;
            break;
          }
          case Missions.RegistrationMission: {
            missionComponent = <RegistrationCompletedMission key={mission} />;
            break;
          }
          case Missions.TwitterMission: {
            missionComponent = <TwitterMission key={mission} />;
            break;
          }
          default:
            return null;
        }
        return (
          <Disclosure as="div" key={mission}>
            {missionComponent}
          </Disclosure>
        );
      })}
    </div>
  );
};

export default EarlyAccessActive;
