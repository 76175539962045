import * as ethers from 'ethers';

const gweiToEth = (gweiValue: ethers.BigNumberish | null | undefined) => {
  if (!gweiValue) return null;
  return ethers.utils.formatUnits(gweiValue, 'gwei');
};

const shortenWalletAddress = (walletAddress?: string) => {
  if (!walletAddress) return undefined;

  const walletAddressPrefix = walletAddress.slice(0, 6) || '0x0000';
  const walletAddressSuffix =
    walletAddress.slice(walletAddress.length - 4, walletAddress.length) ||
    '0000';

  return `${walletAddressPrefix}...${walletAddressSuffix}`;
};

export { gweiToEth, shortenWalletAddress };
