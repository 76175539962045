export enum OauthErrors {
  FAILED_OAUTH = 'failed_oauth',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  FAILED_IDENTITY = 'failed_identity',
}

export enum DiscordErrors {
  FAILED_OAUTH = 'failed_oauth',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  DISCORD_GUILD_ID_MISSING = 'discord_guild_id_missing',
  DISCORD_ROLE_ID_MISSING = 'discord_role_id_missing',
  DISCORD_FAILED_TO_ASSIGN_ROLE = 'discord_failed_to_assign_role',
}

export enum TwitterErrors {
  FAILED_OAUTH = 'failed_oauth',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  TWITTER_TOO_MANY_REQUESTS = 'twitter_too_many_requests',
  TWITTER_NOT_FOLLOWING = 'twitter_not_following',
}

export enum NotificationLevels {
  'INFO' = 'info',
  'SUCCESS' = 'success',
  'WARNING' = 'warning',
  'ERROR' = 'error',
}

export enum MantineColors {
  DARK = 'dark',
  GRAY = 'gray',
  RED = 'red',
  PINK = 'pink',
  GRAPE = 'grape',
  VIOLET = 'violet',
  INDIGO = 'indigo',
  BLUE = 'blue',
  CYAN = 'cyan',
  GREEN = 'green',
  LIME = 'lime',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  TEAL = 'teal',
}
