import { css } from '@emotion/react';
import { useStore } from '../../store/store';
import Button from '../atoms/Button';
import { formatEther, parseEther } from 'ethers/lib/utils';
import ethLogo from '../../assets/images/eth-logo.png';
import React from 'react';
import { Colors } from '../../themes/colors';
import { ChainIDMap } from '../../types/shared';

type MintFooterProps = {};

const MintFooter = (props: MintFooterProps) => {
  const theme = useStore((s) => s.themeSlice.theme);
  const tokenCurrentSupply = useStore((s) => s.mintSlice.tokenCurrentSupply);
  const tokenMaxSupply = useStore((s) => s.mintSlice.tokenMaxSupply);
  const mint = useStore((s) => s.mintSlice.mint);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  const amountOfTokensSelected = useStore(
    (s) => s.mintSlice.amountOfTokensSelected
  );
  const contractDetails = useStore((s) => s.mintSlice.contractDetails);
  const walletLimit = useStore((s) => s.mintSlice.walletLimit);
  const isContractPaused = useStore((s) => s.mintSlice.isContractPaused);
  const ethPrice = useStore((s) => s.projectSlice.appState.phase?.priceInEther);
  if (!ethPrice)
    return (
      <span>
        Token price has not been specified, contact your administrator
      </span>
    );
  const isAuthRequired = useStore(
    (s) => s.projectSlice.appState.isAuthRequired
  );

  const totalPrice = formatEther(
    parseEther(ethPrice).mul(amountOfTokensSelected)
  );
  const contractChainID = useStore((s) => s.mintSlice.contractDetails?.chainId);
  const walletChainID = useStore((s) => s.userSlice.chainID);

  const isMintButtonDisabled =
    tokenCurrentSupply === 0 ||
    tokenMaxSupply === 0 ||
    !contractDetails ||
    (!isUserSignedIn && isAuthRequired) ||
    !walletAddress ||
    contractChainID !== walletChainID ||
    walletLimit <= 0 ||
    isContractPaused;

  const renderBox = (
    title: string,
    body: React.ReactNode | string,
    flex: string,
    color: string
  ) => (
    <div
      className="mr-2 p-2 flex flex-col items-end justify-center rounded-lg select-none transition-colors"
      css={css`
        flex: 1 1 ${flex};
        background: ${color};
        color: ${Colors.DARK_GRAY};

        ${color === theme.primary.background &&
        `
          margin-right: 0.5rem;
          color: ${theme.primary.text}
        `}
      `}
    >
      <span>{title}</span>
      <span
        css={css`
          ${typeof body === 'string' &&
          `
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 28px;

        `}
        `}
      >
        {body}
      </span>
    </div>
  );

  let mintButtonTitle = '';
  let mintButtonText = 'Mint';

  if (!walletAddress) {
    mintButtonTitle = 'Connect wallet to continue';
    mintButtonText = 'Connect your wallet to Continue';
  } else if (!isUserSignedIn && isAuthRequired) {
    mintButtonTitle = 'Sign in to Continue';
    mintButtonText = 'Sign in to Continue';
  } else if (!contractDetails) {
    mintButtonTitle = 'Loading contract details';
    mintButtonText = 'Loading contract details';
  } else if (contractChainID !== walletChainID) {
    mintButtonTitle = `Contract network is ${ChainIDMap[contractChainID]}. Wallet network is ${ChainIDMap[walletChainID]}. You must use the ${ChainIDMap[contractChainID]} network to mint.`;
    mintButtonText = 'Wallet/Contract network mismatch';
  } else if (walletLimit <= 0) {
    mintButtonTitle = `You've reached the maximum amount of tokens you are allowed to mint.`;
    mintButtonText = 'Cannot mint anymore tokens';
  } else if (isContractPaused) {
    mintButtonTitle = 'Minting has been paused. Please check back later.';
    mintButtonText = 'Minting has been paused';
  }

  return (
    <div className="flex flex-col flex-auto items-stretch mt-2">
      <span className="flex items-center flex-auto justify-end">
        {renderBox(
          'Price',
          <div className="flex items-center justify-end">
            <img
              src={ethLogo}
              css={css`
                height: 25px;
              `}
              className="mr-1"
            />
            <span
              className="font-bold"
              css={css`
                font-size: 1.5rem;
                line-height: 28px;
                color: ${Colors.DARK_GRAY};
              `}
            >
              {ethPrice}
            </span>
          </div>,
          '25%',
          Colors.LIGHT_GRAY
        )}
        {renderBox(
          'Quantity',
          `${amountOfTokensSelected}x`,
          '25%',
          Colors.LIGHT_GRAY
        )}
        {renderBox('Total Price', totalPrice, '50%', theme.primary.background)}
      </span>

      <Button
        className="mt-6"
        css={css`
          height: 60px;
        `}
        disabled={isMintButtonDisabled}
        color={Colors.BLACK}
        onClick={mint}
        title={mintButtonTitle}
      >
        {mintButtonText}
      </Button>
    </div>
  );
};

export default MintFooter;
