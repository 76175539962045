import { Colors } from './colors';
import { Theme } from '../types/shared';

const KoolAid: Theme = {
  primary: {
    background: Colors.YELLOW,
    text: Colors.DARK_GRAY,
  },
  secondary: {
    background: Colors.GRAY,
    text: Colors.BLACK,
  },
  canvas: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionButton: {
    background: Colors.WHITE,
    text: Colors.DARK_GRAY,
  },
  missionBody: {
    background: Colors.LIGHT_GRAY,
    text: Colors.DARK_GRAY,
  },
};
export default KoolAid;
