// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "twitter.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message NewTwitterMissionRequest
 */
export interface NewTwitterMissionRequest {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string final_redirect_url = 2;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message NewTwitterMissionResponse
 */
export interface NewTwitterMissionResponse {
    /**
     * @generated from protobuf field: string mission_id = 1;
     */
    missionId: string;
    /**
     * @generated from protobuf field: string redirect_url = 2;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message ReauthTwitterMissionRequest
 */
export interface ReauthTwitterMissionRequest {
    /**
     * @generated from protobuf field: string final_redirect_url = 1;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message ReauthTwitterMissionResponse
 */
export interface ReauthTwitterMissionResponse {
    /**
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message CompleteTwitterMissionRequest
 */
export interface CompleteTwitterMissionRequest {
    /**
     * @generated from protobuf field: string mission_id = 1;
     */
    missionId: string;
    /**
     * @generated from protobuf field: string final_redirect_url = 2;
     */
    finalRedirectUrl: string;
}
/**
 * @generated from protobuf message CompleteTwitterMissionResponse
 */
export interface CompleteTwitterMissionResponse {
    /**
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message ShowTwitterMissionResponse
 */
export interface ShowTwitterMissionResponse {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: bool ready = 2;
     */
    ready: boolean;
}
/**
 * @generated from protobuf message EditTwitterMissionRequest
 */
export interface EditTwitterMissionRequest {
}
// @generated message type with reflection information, may provide speed optimized methods
class NewTwitterMissionRequest$Type extends MessageType<NewTwitterMissionRequest> {
    constructor() {
        super("NewTwitterMissionRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewTwitterMissionRequest>): NewTwitterMissionRequest {
        const message = { projectId: "", finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewTwitterMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewTwitterMissionRequest): NewTwitterMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string final_redirect_url */ 2:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewTwitterMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string final_redirect_url = 2; */
        if (message.finalRedirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewTwitterMissionRequest
 */
export const NewTwitterMissionRequest = new NewTwitterMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewTwitterMissionResponse$Type extends MessageType<NewTwitterMissionResponse> {
    constructor() {
        super("NewTwitterMissionResponse", [
            { no: 1, name: "mission_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewTwitterMissionResponse>): NewTwitterMissionResponse {
        const message = { missionId: "", redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewTwitterMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewTwitterMissionResponse): NewTwitterMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mission_id */ 1:
                    message.missionId = reader.string();
                    break;
                case /* string redirect_url */ 2:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewTwitterMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mission_id = 1; */
        if (message.missionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.missionId);
        /* string redirect_url = 2; */
        if (message.redirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewTwitterMissionResponse
 */
export const NewTwitterMissionResponse = new NewTwitterMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReauthTwitterMissionRequest$Type extends MessageType<ReauthTwitterMissionRequest> {
    constructor() {
        super("ReauthTwitterMissionRequest", [
            { no: 1, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReauthTwitterMissionRequest>): ReauthTwitterMissionRequest {
        const message = { finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReauthTwitterMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReauthTwitterMissionRequest): ReauthTwitterMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string final_redirect_url */ 1:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReauthTwitterMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string final_redirect_url = 1; */
        if (message.finalRedirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReauthTwitterMissionRequest
 */
export const ReauthTwitterMissionRequest = new ReauthTwitterMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReauthTwitterMissionResponse$Type extends MessageType<ReauthTwitterMissionResponse> {
    constructor() {
        super("ReauthTwitterMissionResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReauthTwitterMissionResponse>): ReauthTwitterMissionResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReauthTwitterMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReauthTwitterMissionResponse): ReauthTwitterMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReauthTwitterMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReauthTwitterMissionResponse
 */
export const ReauthTwitterMissionResponse = new ReauthTwitterMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteTwitterMissionRequest$Type extends MessageType<CompleteTwitterMissionRequest> {
    constructor() {
        super("CompleteTwitterMissionRequest", [
            { no: 1, name: "mission_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "final_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteTwitterMissionRequest>): CompleteTwitterMissionRequest {
        const message = { missionId: "", finalRedirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteTwitterMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteTwitterMissionRequest): CompleteTwitterMissionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mission_id */ 1:
                    message.missionId = reader.string();
                    break;
                case /* string final_redirect_url */ 2:
                    message.finalRedirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteTwitterMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mission_id = 1; */
        if (message.missionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.missionId);
        /* string final_redirect_url = 2; */
        if (message.finalRedirectUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.finalRedirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompleteTwitterMissionRequest
 */
export const CompleteTwitterMissionRequest = new CompleteTwitterMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteTwitterMissionResponse$Type extends MessageType<CompleteTwitterMissionResponse> {
    constructor() {
        super("CompleteTwitterMissionResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteTwitterMissionResponse>): CompleteTwitterMissionResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteTwitterMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteTwitterMissionResponse): CompleteTwitterMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteTwitterMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompleteTwitterMissionResponse
 */
export const CompleteTwitterMissionResponse = new CompleteTwitterMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowTwitterMissionResponse$Type extends MessageType<ShowTwitterMissionResponse> {
    constructor() {
        super("ShowTwitterMissionResponse", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ready", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ShowTwitterMissionResponse>): ShowTwitterMissionResponse {
        const message = { username: "", ready: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowTwitterMissionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowTwitterMissionResponse): ShowTwitterMissionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* bool ready */ 2:
                    message.ready = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowTwitterMissionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* bool ready = 2; */
        if (message.ready !== false)
            writer.tag(2, WireType.Varint).bool(message.ready);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowTwitterMissionResponse
 */
export const ShowTwitterMissionResponse = new ShowTwitterMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditTwitterMissionRequest$Type extends MessageType<EditTwitterMissionRequest> {
    constructor() {
        super("EditTwitterMissionRequest", []);
    }
    create(value?: PartialMessage<EditTwitterMissionRequest>): EditTwitterMissionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditTwitterMissionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditTwitterMissionRequest): EditTwitterMissionRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EditTwitterMissionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditTwitterMissionRequest
 */
export const EditTwitterMissionRequest = new EditTwitterMissionRequest$Type();
