import { Missions } from '../../../../../store/missionSlice';
import { useStore } from '../../../../../store/store';
import DisclosureBody from './Disclosure/DisclosureBody';
import DisclosureButton from './Disclosure/DisclosureButton';

const RegistrationCompletedMission = () => {
  const completeMission = useStore((s) => s.missionSlice.completeMission);
  const isRegistrationMissionComplete = useStore(
    (s) => s.missionSlice.missions.RegistrationMission.isComplete
  );
  const registerMissionsComplete = useStore(
    (s) => s.registrationSlice.registerMissionsComplete
  );
  const isReadyForRegistration = useStore(
    (s) => s.missionSlice.nextMission === Missions.RegistrationMission
  );
  const updateAppState = useStore((s) => s.projectSlice.updateAppState);
  return (
    <>
      <DisclosureButton
        mission={Missions.RegistrationMission}
        name="Complete Registration"
      />
      <DisclosureBody
        mission={Missions.RegistrationMission}
        description={
          isRegistrationMissionComplete
            ? 'Congratulations! You are now registered!'
            : 'Complete all steps above to register'
        }
        actionButton={
          !isReadyForRegistration || isRegistrationMissionComplete
            ? null
            : {
                label: 'Register',
                onClick: async () => {
                  await registerMissionsComplete();
                  completeMission(Missions.RegistrationMission);
                  updateAppState({ hasUserCompletedPhase: true });
                },
              }
        }
      />
    </>
  );
};

export default RegistrationCompletedMission;
