import { PhaseKey } from '../../../constants/shared';
import { useStore } from '../../../store/store';
import EarlyAccessActive from './stages/EarlyAccessActive';
import EarlyAccessEnded from './stages/EarlyAccessEnded';
import EarlyAccessUserComplete from './stages/EarlyAccessUserComplete';
import GeneralComingSoon from '../GeneralComingSoon';
import { useEffect } from 'react';
import Header from '../../mintkitAtoms/Header';

const EarlyAccess = () => {
  const appState = useStore((s) => s.projectSlice.appState);
  const projectID = useStore((s) => s.projectSlice.projectID);
  const fetchMissions = useStore((s) => s.missionSlice.fetchMissions);
  const isWalletConnected = useStore((s) => s.userSlice.walletAddress);
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);

  useEffect(() => {
    if (!projectID) return;

    fetchMissions();
  }, [projectID]);

  let Component = null;
  let headerText = '';
  switch (appState.phaseKey) {
    case PhaseKey.active: {
      if (appState.hasUserCompletedPhase) {
        headerText = "You're registered!";
        Component = <EarlyAccessUserComplete />;
      } else {
        if (!isWalletConnected) headerText = 'Connect wallet to continue';
        else if (!isUserSignedIn) {
          headerText = 'Sign in to verify status';
        } else {
          headerText = 'Complete steps to register';
        }
        Component = <EarlyAccessActive />;
      }
      break;
    }
    case PhaseKey.startingSoon:
      headerText = 'Stay tuned for updates!';
      Component = <GeneralComingSoon />;
      break;
    case PhaseKey.ended:
      Component = <EarlyAccessEnded />;
      break;
    case PhaseKey.inactive:
      Component = <GeneralComingSoon inactive />;
      break;
  }

  return (
    <div className="h-full flex flex-col">
      <Header headerBody={headerText} />
      {Component}
    </div>
  );
};

export default EarlyAccess;
