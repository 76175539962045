import { useStore } from '../../../store/store';
import MintComingSoon from '../MintComingSoon';
import MintEnded from '../MintEnded';
import MintUserComplete from '../MintUserComplete';
import { PhaseKey } from '../../../constants/shared';
import MintSelection from '../MintSelection';
import { format } from 'date-fns-tz';
import { MintStatusType } from '../../../store/projectSlice';
import { Loader } from '@mantine/core';
import AuthGate from './AuthGate';
import Header from '../../mintkitAtoms/Header';

const PrivateMintContainer = () => {
  const appState = useStore((s) => s.projectSlice.appState);
  const tokenName = useStore((s) => s.projectSlice.tokenName);
  const startsAt = useStore((s) => s.projectSlice.appState.phase?.startsAt);
  const amount = useStore((s) => s.mintSlice.amountOfTokensSelected);
  const mintStatus = useStore((s) => s.projectSlice.appState.mintStatus);
  const isUserEligibleToMint = useStore(
    (s) => s.projectSlice.appState.isUserEligibleToMint
  );
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  let Component = null;
  let headerBody: string | React.ReactNode = '';

  switch (appState.phaseKey) {
    case PhaseKey.active: {
      if (appState.hasUserCompletedPhase) {
        switch (mintStatus.status) {
          case MintStatusType.NOT_STARTED:
            headerBody =
              'There was an error while loading the page. Please refresh and try again.';
            break;
          case MintStatusType.IN_PROGRESS:
            headerBody = (
              <div className="flex flex-col justify-center items-center mb-2">
                Your transaction for {amount} {tokenName} token
                {amount > 1 ? 's ' : ' '}
                is processing
                <span className="mt-8">
                  <Loader />
                </span>
              </div>
            );
            break;
          case MintStatusType.SUCCESS:
            headerBody = 'Congratulations! Your mint was successful.';
            break;
          case MintStatusType.FAILED:
            headerBody = `There was an error while processing your transaction`;
            break;
        }

        Component = <MintUserComplete />;
      } else {
        if (isUserEligibleToMint && walletAddress) {
          headerBody = `How many ${tokenName} tokens would you like to mint?`;
          Component = <MintSelection />;
        } else {
          headerBody = 'Login to check eligibility';
          Component = <AuthGate />;
        }
      }
      break;
    }
    case PhaseKey.startingSoon:
      headerBody = format(
        new Date(startsAt),
        "'Minting opens at ' h:mm aaa zzz, EEEE, MMMM d, u"
      );
      Component = <MintComingSoon />;
      break;
    case PhaseKey.ended:
      Component = <MintEnded />;
      break;
    case PhaseKey.inactive:
      Component = null;
      break;
  }

  return (
    <div className="h-full flex flex-col justify-between">
      <Header headerBody={headerBody} />
      {Component}
    </div>
  );
};

export default PrivateMintContainer;
