import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { requireProjectID, requireUserID, Store } from './store';
import * as UsersProto from '../async/proto/users/users';
import { Missions } from './missionSlice';
import { ProjectPhase } from '../constants/shared';

export enum OauthProvider {
  twitter = 'twitter',
  discord = 'discord',
}
export interface AuthSlice {
  userID?: string;
  isUserSignedIn: boolean;
  identities: UsersProto.PublicUser_Identity[];
  fetchIsUserSignedIn: () => Promise<void>;
  signOut: () => Promise<void>;
  signIn: (message: string, signature: string) => Promise<void>;
  oauth: (provider: OauthProvider) => void;
  removeIdentity: (provider: OauthProvider) => void;
}

export const authSlice: AuthSlice = lens(
  (setState, getState, api: StoreApi<Store>) => ({
    identities: [],
    isUserSignedIn: false,
    fetchIsUserSignedIn: async () => {
      const projectID = api.getState().projectSlice.projectID;
      const isAuthRequired =
        api.getState().projectSlice.appState.isAuthRequired;
      if (!projectID || !isAuthRequired) return;

      try {
        const response = await axios.get(`/projects/${projectID}/user`);
        const { data } = UsersProto.ShowPublicUserResponse.fromJson(
          response.data
        );
        setState({
          isUserSignedIn: true,
          identities: data.identities,
          userID: data.id,
        });

        if (data.allMissionsCompleted) {
          api
            .getState()
            .missionSlice.completeMission(Missions.RegistrationMission);
        }
        api.getState().projectSlice.updateAppState({
          isUserEligibleToMint: data.isEligible,
          hasUserCompletedPhase: data.allMissionsCompleted,
        });
      } catch (e) {
        setState({ isUserSignedIn: false, identities: [], userID: undefined });
        api.getState().projectSlice.updateAppState({
          isUserEligibleToMint: false,
          hasUserCompletedPhase: false,
        });
      }
    },
    signOut: async () => {
      const projectID = api.getState().projectSlice.projectID;
      if (!projectID) return;

      await axios.post(`/projects/${projectID}/users/signout`);
      getState().fetchIsUserSignedIn();
    },
    signIn: async (message: string, signature: string): Promise<void> => {
      const projectID = api.getState().projectSlice.projectID;
      const address = api.getState().userSlice.walletAddress;
      const chain = process.env.NETWORK_ID;

      if (!projectID || !address) return;

      const scrapedNonce = message
        .match(/Nonce\: [a-z0-9]+/gi)[0]
        .split('Nonce: ')[1];

      await axios.post(
        `/projects/${projectID}/users/connect`,
        {
          chain,
          address,
          message,
          signature,
          url: api.getState().universalSlice.hostOrigin,
        },
        { headers: { 'X-CSRF-Token': scrapedNonce } }
      );

      const phase = api.getState().projectSlice.appState.phase?.kind;
      if (phase === ProjectPhase.EarlyAccess) {
        api.getState().missionSlice.fetchMissions(true);
      }
    },
    oauth: async (provider: OauthProvider) => {
      const { universalSlice } = api.getState();
      const projectID = requireProjectID();

      const finalRedirectUrl = universalSlice.hostOrigin;
      const sdk = universalSlice.sdk;
      const { data } = await axios.post(
        `/projects/${projectID}/user/oauth/${provider}`,
        {
          finalRedirectUrl,
        } as UsersProto.UserOAuthRequest
      );
      const { redirectUrl } = UsersProto.UserOAuthResponse.fromJson(data);

      if (redirectUrl) {
        sdk.redirect(redirectUrl);
      }
    },
    removeIdentity: async (provider: OauthProvider) => {
      const projectID = requireProjectID();
      const userId = requireUserID();
      const identities = getState().identities;
      let id = identities.find((i) => i.provider === provider).id;

      await axios.delete(
        `/projects/${projectID}/user_identities?user_identity_id=${id}`
      );
      getState().fetchIsUserSignedIn();
    },
  })
);
