declare var Pluto: any;

import { Container } from '@mantine/core';
import { useStore } from '../store/store';
import {
  MAX_DELAY_VALUE,
  PresentationMode,
  WIDTH_BREAKPOINT_TYPES,
} from '../types/shared';
import React, { useEffect, useRef } from 'react';
import { css, Global } from '@emotion/react';
import EarlyAccess from './phases/earlyAccess/EarlyAccessContainer';
import {
  DEFAULT_WIDGET_WIDTH,
  Network,
  PhaseKey,
  ProjectPhase,
} from '../constants/shared';
import PrivateMintContainer from './phases/privateMint/PrivateMintContainer';
import PublicMintContainer from './phases/publicMint/PublicMintContainer';
import ProtectedPublicMintContainer from './phases/protectedPublicMint/ProtectedPublicMintContainer';
import GeneralComingSoon from './phases/GeneralComingSoon';
import Notification from './mintkitAtoms/Notification';
import LoadErrorHandler from './LoadErrorHandler';
import AppHeader from './AppHeader';
import { applyMediaWidth, applyPhoneStyling } from '../store/universalSlice';
import MainTokenImage from './mintkitAtoms/MainTokenImage';

const AppContainer = () => {
  const { phase } = useStore((s) => s.projectSlice.appState);
  const theme = useStore((s) => s.themeSlice.theme);
  const notifications = useStore((s) => s.notificationSlice.notifications);
  const widthBreakpoint = useStore((s) => s.universalSlice.widthBreakpoint);
  const isMobile = useStore((s) => s.universalSlice.isMobile);

  const endsAt = useStore((s) => s.projectSlice.appState.phase?.endsAt);
  const updateProjectPhaseKey = useStore(
    (s) => s.projectSlice.updateProjectPhaseKey
  );
  const isProjectLoaded = useStore((s) => s.projectSlice.isProjectLoaded);
  const widgetDimensions = useStore((s) => s.universalSlice.widgetDimensions);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let endsAtTimeout: NodeJS.Timeout;
    if (endsAt) {
      clearInterval(endsAtTimeout);
      setTimeout(() => {
        updateProjectPhaseKey(PhaseKey.ended);
      }, Math.min(MAX_DELAY_VALUE, new Date(endsAt).getTime() - Date.now()));
    }
  }, [endsAt]);

  useEffect(() => {
    if (applyPhoneStyling()) {
      // May uncomment this. Testing what it looks like on phone first
      // document.documentElement.style.fontSize = '18px';
    } else {
      document.documentElement.style.fontSize = '16px';
    }
  }, [isMobile, widthBreakpoint]);

  let Component = null;

  switch (phase?.kind) {
    case ProjectPhase.EarlyAccess:
      Component = <EarlyAccess />;
      break;
    case ProjectPhase.PrivateMint:
      Component = <PrivateMintContainer />;
      break;
    case ProjectPhase.PublicMint:
      Component = <PublicMintContainer />;
      break;
    case ProjectPhase.ProtectedPublicMint:
      Component = <ProtectedPublicMintContainer />;
      break;
    default:
      Component = <GeneralComingSoon inactive />;
      break;
  }

  if (!isProjectLoaded) return null;

  return (
    <>
      <Global
        styles={css`
          ${theme.fonts?.fontSources.map((src) => `@import url('${src}');`)}

          * {
            letter-spacing: 0.0305rem !important;
          }
        `}
      />

      <LoadErrorHandler />

      <Container
        id="AppContainer"
        css={css`
          background: ${theme.canvas.background};
          color: ${theme.canvas.text};
          line-height: 24px;
          position: relative;
          overflow: hidden;
          max-width: none;

          ${theme.fonts && `font-family: '${theme.fonts.fontFamily}';`};

          ${applyPhoneStyling() ? 'border-radius: 0;' : 'border-radius: 1rem;'}
          flex: 0 1 auto;
          margin: 0;
          width: auto;
          display: flex;
        `}
        className="h-full rounded-2xl relative flex p-0 !tracking-normal transition-all"
      >
        <MainTokenImage width={ref.current?.scrollHeight} />
        <div
          className="flex flex-col transition-all"
          css={css`
            flex: 0 1 auto;
            width: ${widgetDimensions.contentWidth}px;
            max-width: ${widgetDimensions.contentWidth}px;
          `}
        >
          <AppHeader />
          <div className="flex" ref={ref}>
            <div
              className="absolute top-2"
              css={css`
                width: calc(100% - 2rem);
              `}
            >
              {notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </div>
            <div
              css={css`
                flex: 1 1 auto;
                padding: 40px;
              `}
            >
              {Component}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AppContainer;
