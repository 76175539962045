// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "projects.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Project
 */
export interface Project {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string team_id = 2;
     */
    teamId: string;
    /**
     * @generated from protobuf field: string phase_id = 3;
     */
    phaseId: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string components_last_seen_at = 5;
     */
    componentsLastSeenAt: string;
    /**
     * @generated from protobuf field: repeated string domains = 6;
     */
    domains: string[];
    /**
     * @generated from protobuf field: string image_url = 7;
     */
    imageUrl: string;
}
/**
 * @generated from protobuf message PublicProject
 */
export interface PublicProject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: PublicProject.Phase phase = 3;
     */
    phase?: PublicProject_Phase;
    /**
     * @generated from protobuf field: string phase_key = 4;
     */
    phaseKey: string;
    /**
     * @generated from protobuf field: string image_url = 5;
     */
    imageUrl: string;
}
/**
 * @generated from protobuf message PublicProject.Phase
 */
export interface PublicProject_Phase {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string kind = 2;
     */
    kind: string;
    /**
     * @generated from protobuf field: string price_in_ether = 3;
     */
    priceInEther: string;
    /**
     * @generated from protobuf field: map<string, string> contract_func_map = 5;
     */
    contractFuncMap: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string starts_at = 6;
     */
    startsAt: string; // RFC3339
    /**
     * @generated from protobuf field: string ends_at = 7;
     */
    endsAt: string; // RFC33339
}
/**
 * @generated from protobuf message ProjectErrors
 */
export interface ProjectErrors {
    /**
     * @generated from protobuf field: optional string id = 1;
     */
    id?: string;
    /**
     * @generated from protobuf field: optional string team_id = 2;
     */
    teamId?: string;
    /**
     * @generated from protobuf field: optional string phase_id = 3;
     */
    phaseId?: string;
    /**
     * @generated from protobuf field: optional string name = 4;
     */
    name?: string;
    /**
     * @generated from protobuf field: optional string domains = 5;
     */
    domains?: string;
}
/**
 * GET /projects/:id
 *
 * @generated from protobuf message ShowProjectResponse
 */
export interface ShowProjectResponse {
    /**
     * @generated from protobuf field: Project data = 1;
     */
    data?: Project;
    /**
     * @generated from protobuf field: ProjectErrors errors = 2;
     */
    errors?: ProjectErrors;
}
/**
 * @generated from protobuf message ShowPublicProjectResponse
 */
export interface ShowPublicProjectResponse {
    /**
     * @generated from protobuf field: PublicProject data = 1;
     */
    data?: PublicProject;
}
/**
 * GET /projects
 *
 * @generated from protobuf message IndexProjectResponse
 */
export interface IndexProjectResponse {
    /**
     * @generated from protobuf field: repeated Project data = 1;
     */
    data: Project[];
    /**
     * @generated from protobuf field: ProjectErrors errors = 2;
     */
    errors?: ProjectErrors;
}
/**
 * POST /projects
 *
 * @generated from protobuf message NewProjectRequest
 */
export interface NewProjectRequest {
    /**
     * @generated from protobuf field: string team_id = 1;
     */
    teamId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string domains = 3;
     */
    domains: string[];
    /**
     * @generated from protobuf field: string image_base64 = 4;
     */
    imageBase64: string;
}
/**
 * @generated from protobuf message NewProjectResponse
 */
export interface NewProjectResponse {
    /**
     * @generated from protobuf field: Project data = 1;
     */
    data?: Project;
    /**
     * @generated from protobuf field: ProjectErrors errors = 2;
     */
    errors?: ProjectErrors;
}
/**
 * PUT /projects/:id
 *
 * @generated from protobuf message EditProjectRequest
 */
export interface EditProjectRequest {
    /**
     * @generated from protobuf field: string team_id = 1;
     */
    teamId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string phase_id = 3;
     */
    phaseId: string;
    /**
     * @generated from protobuf field: repeated string domains = 4;
     */
    domains: string[];
    /**
     * @generated from protobuf field: string image_base64 = 5;
     */
    imageBase64: string;
}
/**
 * @generated from protobuf message EditProjectResponse
 */
export interface EditProjectResponse {
    /**
     * @generated from protobuf field: Project data = 1;
     */
    data?: Project;
    /**
     * @generated from protobuf field: ProjectErrors errors = 2;
     */
    errors?: ProjectErrors;
}
/**
 * DELETE /projects/:id
 *
 * @generated from protobuf message DeleteProjectResponse
 */
export interface DeleteProjectResponse {
    /**
     * @generated from protobuf field: Project data = 1;
     */
    data?: Project;
    /**
     * @generated from protobuf field: ProjectErrors errors = 2;
     */
    errors?: ProjectErrors;
}
/**
 * GET /projects/:project_id/signed_token?function=mint&user_address=ethereum://0x9c65bBFB8637A4007635cdb056AA0e20392C26d2
 *
 * @generated from protobuf message SignedTokenResponse
 */
export interface SignedTokenResponse {
    /**
     * @generated from protobuf field: string contract_address = 1;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: uint32 chain_id = 2;
     */
    chainId: number;
    /**
     * @generated from protobuf field: string abi = 3;
     */
    abi: string;
    /**
     * @generated from protobuf field: string function = 4;
     */
    function: string; // mapping for ?function above
    /**
     * @generated from protobuf field: string function_signature = 5;
     */
    functionSignature: string;
    /**
     * names can be empty
     *
     * @generated from protobuf field: string json_inputs = 6;
     */
    jsonInputs: string; // json encoded [{name: "", type: "uint256", value: x}, ..]
    /**
     * @generated from protobuf field: string json_outputs = 7;
     */
    jsonOutputs: string; // json encoded [{name: "", type: "uint256"}, ..]
}
/**
 * GET /projects/:id/contract?function=totalSupply
 *
 * @generated from protobuf message ContractCallResponse
 */
export interface ContractCallResponse {
    /**
     * @generated from protobuf field: string contract_address = 1;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: uint32 chain_id = 2;
     */
    chainId: number;
    /**
     * @generated from protobuf field: string abi = 3;
     */
    abi: string;
    /**
     * @generated from protobuf field: string function = 4;
     */
    function: string; // mapping for ?function above
    /**
     * @generated from protobuf field: string function_signature = 5;
     */
    functionSignature: string;
    /**
     * names can be empty
     *
     * @generated from protobuf field: string json_inputs = 6;
     */
    jsonInputs: string; // json encoded [{name: "", type: "uint256", value: x}, ...]
    /**
     * @generated from protobuf field: string json_outputs = 7;
     */
    jsonOutputs: string; // json encoded [{name: "", type: "uint256", value: x}, ...]
}
// @generated message type with reflection information, may provide speed optimized methods
class Project$Type extends MessageType<Project> {
    constructor() {
        super("Project", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "team_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phase_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "components_last_seen_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Project>): Project {
        const message = { id: "", teamId: "", phaseId: "", name: "", componentsLastSeenAt: "", domains: [], imageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Project>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Project): Project {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string team_id */ 2:
                    message.teamId = reader.string();
                    break;
                case /* string phase_id */ 3:
                    message.phaseId = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string components_last_seen_at */ 5:
                    message.componentsLastSeenAt = reader.string();
                    break;
                case /* repeated string domains */ 6:
                    message.domains.push(reader.string());
                    break;
                case /* string image_url */ 7:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Project, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string team_id = 2; */
        if (message.teamId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.teamId);
        /* string phase_id = 3; */
        if (message.phaseId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phaseId);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string components_last_seen_at = 5; */
        if (message.componentsLastSeenAt !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.componentsLastSeenAt);
        /* repeated string domains = 6; */
        for (let i = 0; i < message.domains.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.domains[i]);
        /* string image_url = 7; */
        if (message.imageUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Project
 */
export const Project = new Project$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublicProject$Type extends MessageType<PublicProject> {
    constructor() {
        super("PublicProject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phase", kind: "message", T: () => PublicProject_Phase },
            { no: 4, name: "phase_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PublicProject>): PublicProject {
        const message = { id: "", name: "", phaseKey: "", imageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PublicProject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublicProject): PublicProject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* PublicProject.Phase phase */ 3:
                    message.phase = PublicProject_Phase.internalBinaryRead(reader, reader.uint32(), options, message.phase);
                    break;
                case /* string phase_key */ 4:
                    message.phaseKey = reader.string();
                    break;
                case /* string image_url */ 5:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublicProject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* PublicProject.Phase phase = 3; */
        if (message.phase)
            PublicProject_Phase.internalBinaryWrite(message.phase, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string phase_key = 4; */
        if (message.phaseKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phaseKey);
        /* string image_url = 5; */
        if (message.imageUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PublicProject
 */
export const PublicProject = new PublicProject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublicProject_Phase$Type extends MessageType<PublicProject_Phase> {
    constructor() {
        super("PublicProject.Phase", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "price_in_ether", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "contract_func_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "starts_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "ends_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PublicProject_Phase>): PublicProject_Phase {
        const message = { id: "", kind: "", priceInEther: "", contractFuncMap: {}, startsAt: "", endsAt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PublicProject_Phase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublicProject_Phase): PublicProject_Phase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string kind */ 2:
                    message.kind = reader.string();
                    break;
                case /* string price_in_ether */ 3:
                    message.priceInEther = reader.string();
                    break;
                case /* map<string, string> contract_func_map */ 5:
                    this.binaryReadMap5(message.contractFuncMap, reader, options);
                    break;
                case /* string starts_at */ 6:
                    message.startsAt = reader.string();
                    break;
                case /* string ends_at */ 7:
                    message.endsAt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: PublicProject_Phase["contractFuncMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PublicProject_Phase["contractFuncMap"] | undefined, val: PublicProject_Phase["contractFuncMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field PublicProject.Phase.contract_func_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: PublicProject_Phase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string kind = 2; */
        if (message.kind !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.kind);
        /* string price_in_ether = 3; */
        if (message.priceInEther !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.priceInEther);
        /* map<string, string> contract_func_map = 5; */
        for (let k of Object.keys(message.contractFuncMap))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.contractFuncMap[k]).join();
        /* string starts_at = 6; */
        if (message.startsAt !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.startsAt);
        /* string ends_at = 7; */
        if (message.endsAt !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.endsAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PublicProject.Phase
 */
export const PublicProject_Phase = new PublicProject_Phase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectErrors$Type extends MessageType<ProjectErrors> {
    constructor() {
        super("ProjectErrors", [
            { no: 1, name: "id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "team_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phase_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "domains", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProjectErrors>): ProjectErrors {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProjectErrors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProjectErrors): ProjectErrors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string id */ 1:
                    message.id = reader.string();
                    break;
                case /* optional string team_id */ 2:
                    message.teamId = reader.string();
                    break;
                case /* optional string phase_id */ 3:
                    message.phaseId = reader.string();
                    break;
                case /* optional string name */ 4:
                    message.name = reader.string();
                    break;
                case /* optional string domains */ 5:
                    message.domains = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProjectErrors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string id = 1; */
        if (message.id !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* optional string team_id = 2; */
        if (message.teamId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.teamId);
        /* optional string phase_id = 3; */
        if (message.phaseId !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.phaseId);
        /* optional string name = 4; */
        if (message.name !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* optional string domains = 5; */
        if (message.domains !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.domains);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectErrors
 */
export const ProjectErrors = new ProjectErrors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowProjectResponse$Type extends MessageType<ShowProjectResponse> {
    constructor() {
        super("ShowProjectResponse", [
            { no: 1, name: "data", kind: "message", T: () => Project },
            { no: 2, name: "errors", kind: "message", T: () => ProjectErrors }
        ]);
    }
    create(value?: PartialMessage<ShowProjectResponse>): ShowProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowProjectResponse): ShowProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Project data */ 1:
                    message.data = Project.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* ProjectErrors errors */ 2:
                    message.errors = ProjectErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Project data = 1; */
        if (message.data)
            Project.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProjectErrors errors = 2; */
        if (message.errors)
            ProjectErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowProjectResponse
 */
export const ShowProjectResponse = new ShowProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShowPublicProjectResponse$Type extends MessageType<ShowPublicProjectResponse> {
    constructor() {
        super("ShowPublicProjectResponse", [
            { no: 1, name: "data", kind: "message", T: () => PublicProject }
        ]);
    }
    create(value?: PartialMessage<ShowPublicProjectResponse>): ShowPublicProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShowPublicProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShowPublicProjectResponse): ShowPublicProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PublicProject data */ 1:
                    message.data = PublicProject.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShowPublicProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PublicProject data = 1; */
        if (message.data)
            PublicProject.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ShowPublicProjectResponse
 */
export const ShowPublicProjectResponse = new ShowPublicProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexProjectResponse$Type extends MessageType<IndexProjectResponse> {
    constructor() {
        super("IndexProjectResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Project },
            { no: 2, name: "errors", kind: "message", T: () => ProjectErrors }
        ]);
    }
    create(value?: PartialMessage<IndexProjectResponse>): IndexProjectResponse {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndexProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndexProjectResponse): IndexProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Project data */ 1:
                    message.data.push(Project.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* ProjectErrors errors */ 2:
                    message.errors = ProjectErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndexProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Project data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Project.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProjectErrors errors = 2; */
        if (message.errors)
            ProjectErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IndexProjectResponse
 */
export const IndexProjectResponse = new IndexProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewProjectRequest$Type extends MessageType<NewProjectRequest> {
    constructor() {
        super("NewProjectRequest", [
            { no: 1, name: "team_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image_base64", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewProjectRequest>): NewProjectRequest {
        const message = { teamId: "", name: "", domains: [], imageBase64: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewProjectRequest): NewProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string team_id */ 1:
                    message.teamId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string domains */ 3:
                    message.domains.push(reader.string());
                    break;
                case /* string image_base64 */ 4:
                    message.imageBase64 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string team_id = 1; */
        if (message.teamId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.teamId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string domains = 3; */
        for (let i = 0; i < message.domains.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.domains[i]);
        /* string image_base64 = 4; */
        if (message.imageBase64 !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.imageBase64);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewProjectRequest
 */
export const NewProjectRequest = new NewProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewProjectResponse$Type extends MessageType<NewProjectResponse> {
    constructor() {
        super("NewProjectResponse", [
            { no: 1, name: "data", kind: "message", T: () => Project },
            { no: 2, name: "errors", kind: "message", T: () => ProjectErrors }
        ]);
    }
    create(value?: PartialMessage<NewProjectResponse>): NewProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewProjectResponse): NewProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Project data */ 1:
                    message.data = Project.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* ProjectErrors errors */ 2:
                    message.errors = ProjectErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Project data = 1; */
        if (message.data)
            Project.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProjectErrors errors = 2; */
        if (message.errors)
            ProjectErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NewProjectResponse
 */
export const NewProjectResponse = new NewProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditProjectRequest$Type extends MessageType<EditProjectRequest> {
    constructor() {
        super("EditProjectRequest", [
            { no: 1, name: "team_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phase_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "domains", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "image_base64", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditProjectRequest>): EditProjectRequest {
        const message = { teamId: "", name: "", phaseId: "", domains: [], imageBase64: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditProjectRequest): EditProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string team_id */ 1:
                    message.teamId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string phase_id */ 3:
                    message.phaseId = reader.string();
                    break;
                case /* repeated string domains */ 4:
                    message.domains.push(reader.string());
                    break;
                case /* string image_base64 */ 5:
                    message.imageBase64 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string team_id = 1; */
        if (message.teamId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.teamId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string phase_id = 3; */
        if (message.phaseId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phaseId);
        /* repeated string domains = 4; */
        for (let i = 0; i < message.domains.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.domains[i]);
        /* string image_base64 = 5; */
        if (message.imageBase64 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.imageBase64);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditProjectRequest
 */
export const EditProjectRequest = new EditProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditProjectResponse$Type extends MessageType<EditProjectResponse> {
    constructor() {
        super("EditProjectResponse", [
            { no: 1, name: "data", kind: "message", T: () => Project },
            { no: 2, name: "errors", kind: "message", T: () => ProjectErrors }
        ]);
    }
    create(value?: PartialMessage<EditProjectResponse>): EditProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EditProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditProjectResponse): EditProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Project data */ 1:
                    message.data = Project.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* ProjectErrors errors */ 2:
                    message.errors = ProjectErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Project data = 1; */
        if (message.data)
            Project.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProjectErrors errors = 2; */
        if (message.errors)
            ProjectErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EditProjectResponse
 */
export const EditProjectResponse = new EditProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProjectResponse$Type extends MessageType<DeleteProjectResponse> {
    constructor() {
        super("DeleteProjectResponse", [
            { no: 1, name: "data", kind: "message", T: () => Project },
            { no: 2, name: "errors", kind: "message", T: () => ProjectErrors }
        ]);
    }
    create(value?: PartialMessage<DeleteProjectResponse>): DeleteProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProjectResponse): DeleteProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Project data */ 1:
                    message.data = Project.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* ProjectErrors errors */ 2:
                    message.errors = ProjectErrors.internalBinaryRead(reader, reader.uint32(), options, message.errors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Project data = 1; */
        if (message.data)
            Project.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProjectErrors errors = 2; */
        if (message.errors)
            ProjectErrors.internalBinaryWrite(message.errors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteProjectResponse
 */
export const DeleteProjectResponse = new DeleteProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignedTokenResponse$Type extends MessageType<SignedTokenResponse> {
    constructor() {
        super("SignedTokenResponse", [
            { no: 1, name: "contract_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chain_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "abi", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "function", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "function_signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "json_inputs", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "json_outputs", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignedTokenResponse>): SignedTokenResponse {
        const message = { contractAddress: "", chainId: 0, abi: "", function: "", functionSignature: "", jsonInputs: "", jsonOutputs: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignedTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignedTokenResponse): SignedTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string contract_address */ 1:
                    message.contractAddress = reader.string();
                    break;
                case /* uint32 chain_id */ 2:
                    message.chainId = reader.uint32();
                    break;
                case /* string abi */ 3:
                    message.abi = reader.string();
                    break;
                case /* string function */ 4:
                    message.function = reader.string();
                    break;
                case /* string function_signature */ 5:
                    message.functionSignature = reader.string();
                    break;
                case /* string json_inputs */ 6:
                    message.jsonInputs = reader.string();
                    break;
                case /* string json_outputs */ 7:
                    message.jsonOutputs = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignedTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string contract_address = 1; */
        if (message.contractAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contractAddress);
        /* uint32 chain_id = 2; */
        if (message.chainId !== 0)
            writer.tag(2, WireType.Varint).uint32(message.chainId);
        /* string abi = 3; */
        if (message.abi !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.abi);
        /* string function = 4; */
        if (message.function !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.function);
        /* string function_signature = 5; */
        if (message.functionSignature !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.functionSignature);
        /* string json_inputs = 6; */
        if (message.jsonInputs !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.jsonInputs);
        /* string json_outputs = 7; */
        if (message.jsonOutputs !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jsonOutputs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SignedTokenResponse
 */
export const SignedTokenResponse = new SignedTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractCallResponse$Type extends MessageType<ContractCallResponse> {
    constructor() {
        super("ContractCallResponse", [
            { no: 1, name: "contract_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chain_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "abi", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "function", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "function_signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "json_inputs", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "json_outputs", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContractCallResponse>): ContractCallResponse {
        const message = { contractAddress: "", chainId: 0, abi: "", function: "", functionSignature: "", jsonInputs: "", jsonOutputs: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContractCallResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContractCallResponse): ContractCallResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string contract_address */ 1:
                    message.contractAddress = reader.string();
                    break;
                case /* uint32 chain_id */ 2:
                    message.chainId = reader.uint32();
                    break;
                case /* string abi */ 3:
                    message.abi = reader.string();
                    break;
                case /* string function */ 4:
                    message.function = reader.string();
                    break;
                case /* string function_signature */ 5:
                    message.functionSignature = reader.string();
                    break;
                case /* string json_inputs */ 6:
                    message.jsonInputs = reader.string();
                    break;
                case /* string json_outputs */ 7:
                    message.jsonOutputs = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContractCallResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string contract_address = 1; */
        if (message.contractAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contractAddress);
        /* uint32 chain_id = 2; */
        if (message.chainId !== 0)
            writer.tag(2, WireType.Varint).uint32(message.chainId);
        /* string abi = 3; */
        if (message.abi !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.abi);
        /* string function = 4; */
        if (message.function !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.function);
        /* string function_signature = 5; */
        if (message.functionSignature !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.functionSignature);
        /* string json_inputs = 6; */
        if (message.jsonInputs !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.jsonInputs);
        /* string json_outputs = 7; */
        if (message.jsonOutputs !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jsonOutputs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractCallResponse
 */
export const ContractCallResponse = new ContractCallResponse$Type();
