import { Missions } from '../../../../../store/missionSlice';
import { useStore } from '../../../../../store/store';
import DisclosureBody from './Disclosure/DisclosureBody';
import DisclosureButton from './Disclosure/DisclosureButton';
import { useEffect } from 'react';

type EthBalancePublicConfig =
  | {
      min_balance_in_ether?: string;
    }
  | undefined;

const EthBalanceMission = () => {
  const completeMission = useStore((s) => s.missionSlice.completeMission);
  const isMissionComplete = useStore(
    (s) => s.missionSlice.missions[Missions.EthMission].isComplete
  );
  const publicConfig = useStore(
    (s) => s.missionSlice.missions[Missions.EthMission].publicConfig
  ) as EthBalancePublicConfig;
  const isUserSignedIn = useStore((s) => s.authSlice.isUserSignedIn);
  const walletAddress = useStore((s) => s.userSlice.walletAddress);
  const minEthBalance = publicConfig?.min_balance_in_ether || '0';

  useEffect(() => {
    if (isMissionComplete || !isUserSignedIn || !walletAddress) return;

    completeMission(Missions.EthMission, true);
  }, [isUserSignedIn, walletAddress]);

  if (!minEthBalance) return null;

  let errorMessage = null;
  if (isMissionComplete)
    errorMessage = 'You have enough ethereum in your wallet to continue';
  else
    errorMessage = `The connected wallet does not contain ${minEthBalance} mainnet ETH`;

  return (
    <>
      <DisclosureButton
        mission={Missions.EthMission}
        name={minEthBalance ? `Have ${minEthBalance} ETH in Wallet` : ''}
      />
      <DisclosureBody
        mission={Missions.EthMission}
        description={
          !isMissionComplete
            ? `Connect with a wallet that has a balance of at least ${minEthBalance} mainnet ETH`
            : ''
        }
        errorMessage={errorMessage}
      />
    </>
  );
};

export default EthBalanceMission;
