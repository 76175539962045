import { css } from '@emotion/react';
import { useStore } from '../../store/store';
import { Colors } from '../../themes/colors';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';

type AuthButtonProps = {
  disconnectedText: string;
  connectedText: string;
  onConnect: () => void;
  onDisconnect: () => void;
  isConnected: boolean;
  isDisabled?: boolean;
  bgColor: Colors;
  textColor: Colors;
};

const AuthButton = (props: AuthButtonProps) => {
  const theme = useStore((s) => s.themeSlice.theme);

  let content;
  if (props.isConnected) {
    content = (
      <>
        <span
          css={css`
            user-select: none;
            background: ${props.bgColor};
            color: ${props.textColor};
          `}
          title={props.connectedText}
          className="w-48 h-10 rounded-lg px-4 flex justify-between items-center transition-colors"
        >
          <span
            className="font-bold text-center flex-auto"
            css={css`
              font-size: 0.875rem;
              line-height: 14px;
            `}
          >
            {props.connectedText}
          </span>
          <CloseButton onClick={props.onDisconnect} color={props.textColor} />
        </span>
      </>
    );
  } else {
    content = (
      <Button
        className="w-48 h-10 rounded-full px-4 flex items-center justify-center text-center"
        disabled={props.isDisabled}
        onClick={props.onConnect}
        color={props.bgColor}
        textColor={props.textColor}
      >
        <span className="truncate">{props.disconnectedText}</span>
      </Button>
    );
  }

  return content;
};

export default AuthButton;
